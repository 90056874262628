import React from "react";
import { Row, Col } from 'react-bootstrap'
import AscComponent from "../../../components/AscComponent";
import ColumnFixTable from '../../../containers/Elements/Table/ColumnFixTable';
import moment from 'moment';


export default class Summaries extends AscComponent {
    constructor(props) {
        super(props);
        this.state = {
            columns: this.getColumnsData(),
            data: Array.isArray(props.state.data) ? props.state.data : [],
            pages: null,
            pageSize: null,
            loading: false,
            filtered: [],
        }

        this.table = React.createRef();
    }

    getCellStyle(rowInfo, cellKey) {
        let totalRow = [];
        if (this.state.data.length > 0) {  
            totalRow = this.state.data.find(row => row.key_as_string === "average" || row.key_as_string === "median");
        }

        let styleObj = {
            background: null,
            color: null
        };

        let cellValue = null;
        if (rowInfo && rowInfo.original && rowInfo.original[cellKey] && 
            rowInfo.original["key_as_string"] !== "average" && rowInfo.original["key_as_string"] !== "median") {
            cellValue = rowInfo && rowInfo.original ? rowInfo.original[cellKey].value : 0;
            let totalValue = totalRow && totalRow[cellKey] ? totalRow[cellKey].value : 0;

            let over20per = totalRow && totalRow[cellKey] ? totalRow[cellKey].value + (totalRow[cellKey].value * 0.2) : 0;
            let less20per = totalRow && totalRow[cellKey] ? totalRow[cellKey].value - (totalRow[cellKey].value * 0.2) : 0;

            if (cellValue && cellValue !== 0 && cellValue !== totalValue
                && !isNaN(cellValue) && isFinite(cellValue)) {
                if (cellValue >= over20per) {
                    styleObj.background = "#d8eaff";
                    styleObj.color = "#4181f7";
                    styleObj.fontWeight = "bold";
                } 
                if (cellValue <= less20per) {
                    styleObj.background = "#ffd6db";
                    styleObj.color = "#f74159";
                    styleObj.fontWeight = "bold";
                }
            }
        }
        return styleObj;
    }

    getColumnsData() {
        let {
            display_col,
            display_col_list,
            view_level
        } = this.props.state;

        let first_head = this.props.langText.Body.Date;

        if (view_level === "CallAnalysisSummaryByOperator") {
            first_head = this.props.langText.Body.OperatorName;
        } else if (view_level === "CallAnalysisSummaryByExtensionNumber") {
            first_head = this.props.langText.Body.ExtensionNumber
        }

        const displayDataList = [
            {
                Header: first_head,
                width: this.props.boardWidth.medium,
                accessor: "key_as_string",
                filterable: false,
                fixed: "left",
                Cell: data => {
                    let display_data = "";
                    switch (view_level) {
                        case "CallAnalysisSummaryByExtensionNumber":
                            if (data.value === "none") {
                                display_data = this.props.langText.Body.ExtensionNumberNotFound;
                            } else {
                                display_data = data.value;
                            }
                            break;
                        case "CallAnalysisSummaryByOperator":
                            if (data.value === "none") {
                                display_data = this.props.langText.Body.OperatorNotFound;
                            } else {
                                display_data = data.value;
                            }
                            break;
                        case "CallAnalysisSummaryByDay":
                            display_data = moment(data.value).format("YYYY-MM-DD");
                            break;
                        case "CallAnalysisSummaryByWeek":
                            display_data = <div>
                                                <div>
                                                    {moment(data.value).format("YYYY-MM-DD")}
                                                </div>
                                                <div>
                                                    {"~" + moment(data.value).add(6, "days").format("YYYY-MM-DD")}
                                                </div>
                                            </div>
                            break;
                        case "CallAnalysisSummaryByMonth":
                            display_data = `${moment(data.value).format("YYYY-MM")}`;
                            break;
                        default:
                            break;
                    }
                    if (data.value === "average") {
                        display_data = this.props.langText.Body.AverageValue;
                    } else if (data.value === "median") {
                        display_data = this.props.langText.Body.MedianValue;
                    }

                    return display_data
                }
            },
            {
                Header: this.props.langText.Body.TotalCallCnt, //総通話回数
                width: this.props.boardWidth.medium,
                accessor: "total_call_count",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "total_call_count") : {}
                    };
                },
                filterable: false,
                fixed: "left",
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + ` ${this.props.langText.Body.TimeCount}`;
                }
            }
        ];

        displayDataList.push(
            {
                Header: this.props.langText.Body.TotalCallTime, //総通話時間
                width: this.props.boardWidth.medium,
                accessor: "total_billsec",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "total_billsec") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "total_billsec"),
                Cell: data => {
                    function addZero(num) {
                        return ((num < 10) ? '0' : '') + num
                    }
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        let hour = parseInt(data.value.value / 3600);
                        let min = parseInt((data.value.value % 3600) / 60);
                        let sec = parseInt(data.value.value % 60);
                        display_data = addZero(hour) + ":" + addZero(min) + ":" + addZero(sec);
                    } else {
                        display_data = "00:00:00";
                    }
                    return display_data;
                }
            },
            {
                Header: this.props.langText.Body.Score, //スコア
                width: this.props.boardWidth.medium,
                accessor: "total_score",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "total_score") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "total_score"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseInt(data.value.value).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + this.props.langText.Body.ScoreAll;
                }
            },
            {
                Header: this.props.langText.Body.TalkingTime, //通話時間
                width: this.props.boardWidth.medium,
                accessor: "billsec",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "billsec") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "billsec"),
                Cell: data => {
                    function addZero(num) {
                        return ((num < 10) ? '0' : '') + num
                    }
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        let hour = parseInt(data.value.value / 3600);
                        let min = parseInt((data.value.value % 3600) / 60);
                        let sec = parseInt(data.value.value % 60);
                        display_data = addZero(hour) + ":" + addZero(min) + ":" + addZero(sec);
                    } else {
                        display_data = "00:00:00";
                    }
                    return display_data;
                }
            },
            {
                Header: this.props.langText.Body.SilenceCount, //沈黙回数
                width: this.props.boardWidth.medium,
                accessor: "silence_count",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "silence_count") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "silence_count"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + ` ${this.props.langText.Body.TimeCount}`;
                }
            },
            {
                Header: this.props.langText.Body.TalkRate, //Talk 比率
                width: this.props.boardWidth.medium,
                accessor: "talk_per",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "talk_per") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "talk_per"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + " %";
                }
            },
            {
                Header: this.props.langText.Body.TalkConflictCount, //被り回数
                width: this.props.boardWidth.medium,
                accessor: "talk_conflict_count",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "talk_conflict_count") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "talk_conflict_count"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + ` ${this.props.langText.Body.TimeCount}`;
                }
            },
            {
                Header: this.props.langText.Body.TalkSpeed, //話速
                width: this.props.boardWidth.medium,
                accessor: "operator_talk_speed",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "operator_talk_speed") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "operator_talk_speed"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + ` ${this.props.langText.Body.CharactersPerSecond}`
                }
            },
            {
                Header: this.props.langText.Body.TalkSpeedDifference, //話速の差
                width: this.props.boardWidth.medium,
                accessor: "operator_talk_speed_diff",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "operator_talk_speed_diff") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "operator_talk_speed_diff"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value != "NaN" 
                        && data.value.value && isFinite(data.value.value)) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data;
                }
            },
            {
                Header: this.props.langText.Body.RallyingCount, //ラリー回数
                width: this.props.boardWidth.medium,
                accessor: "rallying_count",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "rallying_count") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "rallying_count"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + ` ${this.props.langText.Body.TimeCount}`;
                }
            },
            {
                Header: this.props.langText.Body.Frequency, //基本周波数
                width: this.props.boardWidth.medium,
                accessor: "operator_frequency_average",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "operator_frequency_average") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "operator_frequency_average"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data + " Hz";
                }
            },
            {
                Header: this.props.langText.Body.FrequencyDifference, //基本周波数の差
                width: this.props.boardWidth.medium,
                accessor: "operator_frequency_average_diff",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "operator_frequency_average_diff") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "operator_frequency_average_diff"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data;
                }
            },
            {
                Header: this.props.langText.Body.Accent, //抑揚
                width: this.props.boardWidth.medium,
                accessor: "operator_accent",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "operator_accent") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "operator_accent"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data;
                }
            },
            {
                Header: this.props.langText.Body.AccentDifference, //抑揚の差
                width: this.props.boardWidth.medium,
                accessor: "operator_accent_diff",
                getProps: (state, rowInfo, column) => {
                    return {
                        style: this.state.data.length > 0 ? this.getCellStyle(rowInfo, "operator_accent_diff") : {}
                    };
                },
                filterable: false,
                show: display_col_list.length === display_col.length || display_col.some(row => row.value === "operator_accent_diff"),
                Cell: data => {
                    let display_data = "";
                    if (data && data.value && data.value.value) {
                        display_data = parseFloat(data.value.value.toFixed(2)).toLocaleString();
                    } else {
                        display_data = 0;
                    }
                    return display_data;
                }
            },

        );
        return displayDataList;
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} md={12}>
                        <ColumnFixTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            defaultSorted={[this.props.state.sorted]}
                            loading={this.props.state.loading}
                            onSortedChange={(newSorted) => {
                                let sorted = newSorted.length > 0 ? newSorted[0] : {};
                                if (sorted) {
                                    this.props.propSetState({sorted});
                                    this.props.onSummary(sorted);
                                }
                            }}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                            showPaginationBottom={false}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
