export default {
    // 成功
    DataInsertSuccess: "登録しました。",
    DataUpdateSuccess: "更新しました。",
    DataDeleteSuccess: "削除しました。",
    DataSettingSuccess: "設定しました。",
    DataCheckSuccess: "確認しました。",
    DataUnconfirmedSuccess: "未確認にしました。",
    // 件数
    SuccessDataCount: "成功件数：{0}",
    FailedDataCount: "失敗件数：{0}",

    // 行
    Line: "{0}行目：",

    // エラー
    ModalTypeError: "送信情報に不具合があり、実行に失敗しました。",
    AuthCheckError: "権限が無いため、実行に失敗しました。",
    PathAuthCheckError: "権限が無いため、アクセスに失敗しました。",
    DataSelectError: "取得に失敗しました。",
    DataInsertError: "登録に失敗しました。",
    DataUpdateError: "更新に失敗しました。",
    DataDeleteError: "削除に失敗しました。",
    DataSettingError: "設定に失敗しました。",
    CompanySelectError: "会社情報が無いため、情報取得に失敗しました。",
    FindDataError: "必要な情報が不足しているため、実行に失敗しました。",
    GetDataError: "データの取得に失敗しました。管理者に問い合わせください。",
    ExecutionFailedError_Placeholder: "{0}の実行に失敗しました。管理者にお問い合わせください。",
    ProcessingFailedError: "処理に失敗しました。管理者にお問い合わせください。",
    NoDataDeleteError: "削除する項目がありません。",
    GetDataError_Server: "サーバーからデータ取得に失敗しました。",
    DeletedModalError: "選択した項目はすでに削除されています。",
    PrefixDuplicateError: "プレフィックス {0} はすでに登録されているため、登録に失敗しました。",

    // 画面個別設定
    // ユーザー
    User_DataInsertSuccess: "を登録しました。",
    User_InsertRegisteredUserError: "既存ユーザーか過去に登録があるため、登録できませんでした。",
    User_InvalidParameterError:
        "メールアドレス または パスワード のフォーマットが正しくないため、登録できませんでした。",
    User_ChangeSelfPasswordSuccess: "パスワードを変更しました。再ログインをお願いします。",
    User_CancelUserLockingSuccess: "ユーザーのロックを解除しました。",
    UserCsvNoteMailAddressRule: "【{0}】 {1}文字以下/全角を含まない/ユーザー管理に未登録/CSV内で重複しない",
    UserCsvNotePermissionRule: "【{0}】 権限管理に登録されている権限名",

    // ログイン
    SignIn_VerifyLink: "ユーザー認証はこちら",
    SignIn_ResetPasswordLink: "パスワードをお忘れの方はこちら",
    SignIn_UserIsNotExisted: "入力されたユーザーIDは存在しません。",
    SignIn_SignInFailed: "ユーザーIDまたはパスワードが違います。\nあと{0}回ログインに失敗すると、一時的にログイン不可となります。",
    SignIn_SignInFailedAndLocked: "ユーザーIDまたはパスワードが違います。\nログイン失敗回数上限に達したのでログイン不可となります。\n管理者へご連絡をお願いします。",
    SignIn_NotMatchCodeOrExpiredError: "入力されたコードは間違っているか有効期限が切れています。",
    SignIn_NotMatchCodeError: "入力されたコードが間違っています。",
    SignIn_ExecutionFailedError: "実行に失敗しました。",
    SignIn_PasswordResetError:
        "パスワードリセットに失敗しました。\nメールアドレスや認証コードを再確認してください。",
    SignIn_UserNotFoundError: "入力されたメールアドレスは登録されていません。",
    SignIn_TooMuchFailsError: "認証エラーの回数が上限を超えました。再度コードを発行してください。",
    SignIn_NotConfirmedUserError: "未認証のユーザーです。ユーザー認証を行ってください。",
    SignIn_VerifySuccess: "認証に成功しました。",
    SignIn_SentVerificationCode: "認証コードを送信しました。",
    SignIn_ResetPasswordSuccess: "パスワードの再設定に成功しました。",
    SignIn_ConfirmResend: "再送信しますか？",
    NoDepartmentError:
        "所属している拠点またはフロアがありません。\n管理者に所属変更依頼を出してください。",
    SignIn_NoExistMailAlert1: "パスワード再設定用の認証コードをメールアドレスに送信します。",
    SignIn_NoExistMailAlert2:
        "使用しているメールアドレスがメールを受信出来ることを確認して下さい。",
    SignIn_ResetPasswordExpireMessage: "認証コードは１時間以内に入力してください。",
    SignIn_FailureCountMaxOver: "ログイン失敗回数が上限に達したのでログインできません。\n管理者へご連絡をお願いします。",

    // 会社管理
    Company_PrefixDuplicate: "会社プレフィックスが重複したため登録に失敗しました。\n別のプレフィックスで再登録してください。",
    Company_AddingOptionTurnOffErr: "をOFFにできません。",
    Company_AddingOptionTurnOffErr_Ivr_Cause: "■下記の営業カレンダーに「スクリプト」が設定されているため、",
    Company_AddingOptionTurnOffErr_Ivr_Operation: "⇒営業時間管理画面より、設定変更をお願いします。(イレギュラー設定を含みます。)",
    Company_AddingOptionTurnOffErr_IvrTranslation_Cause: "■下記のスクリプトに音声合成（翻訳）機能を使用したブロックが含まれているため、",
    Company_AddingOptionTurnOffErr_IvrTranslation_Operation: "⇒スクリプト管理画面より、削除をお願いします。",
    Company_AddingOptionTurnOffErr_IvrTranslation_Operation_Note: "※既存バージョンの編集は不可になります。スクリプトを転用する場合、お手数ですが削除⇒新規作成をお願い致します。",
    Company_AddingOptionTurnOffErr_CallAnalysis_Cause: "■下記の外線番号が通話品質分析機能を使用中のため、",
    Company_AddingOptionTurnOffErr_Acd_Cause: "■下記の外線番号がACD機能を使用中のため、",
    Company_AddingOptionTurnOffErr_External_Operation: "⇒外線番号管理画面より、設定変更をお願いします。",
    Company_PlaceHolder_LoginPasswordExpireDays: "当会社所属ユーザーのログインパスワード有効期限(日数)を入力してください。",
    CompanyDeleteError_PbxOem: "この会社は以下のPBXでOEM設定されているため削除できません。",

    // 顧客管理
    Customer_overlappedTelNoError: "{0}はすでに登録されている電話番号のため、登録できません。",
    Customer_overCsvRow: "一括で登録は100件までになります。",
    Customer_telPregCheck: "{0}番号の形は登録できません。",
    Customer_CsvTelNoDuplicateException: "電話番号{0}が重複しています。csvの修正を行ってください。",
    Customer_CsvTelNoLimit: "電話番号は5個まで登録できます。",
    Customer_TemplateError: "CSVのヘッダーが不正なため、登録できません。",
    // 内線番号管理
    Extension_XnumUpdateClear: "件更新完了しました。",
    Extension_SequenceSame: "指定した内線番号は既に登録されています。",
    Extension_SequenceNumberDigits:
        "数字３桁で入力して下さい。空白は自動採番、３桁未満は先頭０埋めで登録されます。",
    Omniphone_Login_Status_delete:
        "こちらのログアウト機能はオムニフォンログイン時にログイン情報が残っていてログインできない（内線番号が選択できない）場合にのみ使用してください。\r\n\r\n「OK」ボタンを押下するとオムニフォンのログイン状態が強制的にリセットされます。\r\n\r\n※ 該当内線番号にてログイン状態のオムニフォンがある場合\r\n　 自動的にログアウトされませんのでご注意ください\r\n※ 複数のオムニフォンで同一内線番号にてログインした場合\r\n　 正常に発信・着信ができなくなりますのでご注意ください\r\n",
    Omniphone_Login_Status_delete_result: "ログイン状態をリセットしました。",
    Extension_Depletion: "割り当て可能な内線番号が枯渇しています。管理者にお問い合わせください。",
    Extension_Confirm: "以下の内線番号を登録します。登録を続行する場合はOKを押してください",
    MultiDeleteSuccess: "削除しました。({0}件)",
    Preview_delete: "※まだ削除されていません。※",
    Preview_delete_message1: "下記の内容で一括削除を行います。",
    Preview_delete_message2: "問題がなければ削除ボタンを押してください。",
    SelectMaxMessage: "※一括削除で選択できる内線番号の数は{0}個までとなります。",


    OmniPhone_App_Version_NotFound: "オムニフォンへのログイン情報がありません。",
    // グループ番号管理
    GroupExtensionNum_overlappedNumberError:
        "グループ番号{0}はすでに登録されているため、追加できません。",
    //内線グループ管理
    InboundGroupDuplicateError: "グループ名が重複しているため、登録できません。",
    // ユーザー管理画面
    User_Delete_Check: "本当に削除しますか?",

    // 共通_ファイルアップロード時
    Upload_sizeLimit:
        "指定ファイルはサイズが大きすぎるため、アップロードできません。\n {0}のファイルを選択してください。 ",

    // 共通_未入力エラー
    NoSelected: "{0}を選択してください。",

    //外線設定、拠点設定GW選択してない場合
    ExternalNumber_GwNoSelect: "GWを選択してください。",
    //外線設定、拠点設定10個
    ExternalNumber_DepartmentLimit10: "拠点設定は10個までです。",
    ExternalNumber_SameFloorError: "同じフロアは選択できません。",
    External_SequenceSame: "プレフィックスが重複しています。別のプレフィックスを登録してください。",
    External_Depletion:
        "割り当て可能なプレフィックスが枯渇しています。管理者にお問い合わせください。",
    ExternalNumber_ListMessage1: "指定したリストには登録されている番号がありません。",
    ExternalNumber_ListMessage2: "他のリストを指定するかリストの確認をお願いします。",
    ExternalNumber_DefaultTimeoutSec: "空白はデフォルト値(300秒)で登録されます。",
    ExternalNumber_DefaultTimeoutSecShow: "デフォルト値(300秒)を使用します。",
    ExternalNumber_DefaultTimeoutSecDefault: "デフォルト値(300秒)",
    ExternalNumber_DispensingTime: "空白はデフォルト値(60秒)で登録されます。",
    ExternalNumber_DispensingTimeShow: "デフォルト値(60秒)を使用します。",
    ExternalNumber_DispensingTimeDefault: "デフォルト値(60秒)",
    ExternalNumber_TimeoutSecOverMax: "300秒超は登録できません。",
    ExternalNumber_DispensingTimeLimit: "1～60の間で入力してください",
    ExternalNumber_DispensingTimeOverChannelNumber: "最大着信数以下に設定してください。",
    ExternalNumber_WaitCallTimeOutLimit: "{0}秒より大きい値は設定できません。",

    //外線番号管理スケジュール内線グループ
    ExternalNumber_ScheduleExtensionDelete: "本当にスケジュール内線グループを削除しますか？",
    ExternalNumber_ExistValidation: "※該当日付は既に内線グループが登録されているため、登録できません。",
    ExternalNumber_DuplicateValidation: "※追加データの中にデータの重複があるため、登録できません。",
    ExternalNumber_DateValidation: "※日付設定に問題があります。設定変更後、再度試してください。",

    //外線設定、外線番号が重複されている時
    ExternalNumber_Duplicate: "指定した番号は既に登録されています。",
    TelNumbers_Duplicate: "以下の番号は既に登録されています。",
    Creating: "を作成する(EnterまたはTabで決定)",

    //外線設定、業務管理機能が設定される場合
    ExternalNumber_AcdSettingMessage: "※ACD機能はオムニコンタクト用内線のみ有効となります。",

    //外線設定、通話成立時特殊音声再生が設定される場合
    ExternalNumber_AbjSettingMessage: "通話成立時特殊音声再生を実行します。",

    Download_PopupblockCheck:
        "ダウンロードエラーが発生しました。\n・ご使用のブラウザのポップアップブロックを無効に設定してください。\n・それでも解決しない場合は、時間をおいて実行するか、システム管理者までお問合せください。",
    Download_Failed: "検索結果が0件です。\n検索条件をご確認ください。",
    File_Download_Failed:
        "ダウンロードに失敗しました。時間をおいて再度行うか、\n別拡張子でのダウンロードをお願いします。",
    //IEの場合音声アップロード
    Format_Not_Support: "※フォーマットによっては再生できないファイルがあります。",

    //PW関連
    Password_Validation: "英小文字・数字の組み合わせ(8文字以上)で入力してください。",
    Password_Error: "旧パスワードが違います。",

    Email_Format: "メールアドレスを入力してください。例）email@gmail.com",
    Emails_Format: "メールアドレスを入力してください。例）email1@gmail.com,email2@gmail.com",

    // 権限管理
    Permission_BasePermissionDeleteError: "基本権限のため削除できません。",
    Permission_UserCountDeleteError: "利用ユーザーがいる場合は削除できません。",

    UserNotFoundException: "ユーザーが存在しません。システム管理者までお問合せください。",
    TooManyRequestsException:
        "リクエストが多かったため、削除ができませんでした。後でお試しください。",
    UserNotConfirmedException: "確認されなかったアカウントです。",
    // Validation
    Validation_string_input: `文字を入力してください。`,
    Validation_Number_input: `数字を入力してください。`,
    Validation_TelNumber_input: `電話番号を入力してください。`,
    Validation_NoZero_Number_input: `1以上の数字を入力してください。`,
    Validation_Zero_Number_input: `0以上の数字を入力してください。`,
    Validation_Select_input: `項目を選んでください。`,
    Validation_File_input: `ファイルを選んでください。`,
    SignIn_UserNotFoundException: "ユーザーが存在しません。システム管理者までお問合せください。",
    PasswordResetRequiredException:
        "パスワードがリセットされました。システム管理者までお問合せください。",
    Validation_Telno: `電話番号を入力してください。`,

    //営業時間管理
    Preview_add: "※まだ登録されていません。※",
    Preview_message1: "下記の内容で一括設定を行います。",
    Preview_message2: "問題がなければ登録ボタンを押してください。",
    IrrDeleteSuccess: "イレギュラーが削除されました。",
    IrrInsertSuccess: "イレギュラーが登録・更新されました。",
    TimeSettingMiss: "終了時間を開始時間より大きく設定してください。",
    IrrDateSetError: "開始日から1週間以内の期間に設定してください。",

    // 通話履歴
    CallHistory_FindDataError: "開始日及び終了日、もしくは相手先番号のいずれかを入力してください。",
    CallHistory_DayTermError: "開始日は終了日より前に設定してください。",
    TooManyResultsError:
        "検索結果：{0}件\n検索結果が{1}件以下になるように検索条件を変更して下さい。",
    StartDateTimeCheckError: "終了時間を開始時間より大きく設定してください。",
    SearchQueryTimeOut:
        "検索範囲が広すぎる、もしくは検索条件が少ないため検索条件を変更して下さい。",
    TalkSpeedSlow: `{0}より {1}% 遅い`,
    TalkSpeedFast: `{0}より {1}% 早い`,
    IdOnlySearch: "ID検索の場合は他の条件を含めずに検索されます。",

    //スクリプト利用中メッセージ
    ScriptUsedMsg:
        "このスクリプトは利用中のため、削除や編集はできません。営業時間管理をご確認ください。",
    NoMemoMessage: "※該当バージョンはメモがありません。",
    CallChargeMessage: "※通話料金が発生します。",

    Script_input_check_help: `入力値の読み上げの後、指定音声が再生されます。その後１を押せば次に進み、２を押せば再入力になります。`,
    Script_input_check_example: `※指定音声の例：「で、よろしいですか？よろしければ１を、間違っていれば２を押してください。」`,
    DefaultAudioUse: "デフォルト音声を使用します。",
    NoneAudio: "音声なし",
    Script_ExtenOver: "登録可能な内線数を超過したため、更新できません。",

    Default_value_auto: `デフォルトが設定されます。`,
    Start_node_every_input: `開始に繋がっているアイテムの設定は必ず必要です。`,
    Start_node_nothing: `開始と繋がったアイテムがない為、保存できません。\r\n開始とアイテムを繋がった後、保存してください。`,

    DB_Update_Data_Search_None: `更新するデータが存在しません。`,

    InputDataResetBack: `前の画面に戻ります。\r\n※入力（設定）内容が全部消えます。`,
    UserBelongedDepartmentMessage:
        "※この拠点には{0}人が所属しています。削除すると所属ユーザーでログインができなくなります。",
    UserBelongedFloorMessage:
        "※このフロアには{0}人が所属しています。削除すると所属ユーザーでログインができなくなります。",

    TraslateByteOverLimitMessage: "{0}byte以内の文字を入力してください。",
    SyntheticTextByteOverLimitMessage: "音声合成可能な文字数を超過したため、再生できません。",
    UseScriptSettingMsg: "※スクリプト個別設定を利用すると、外線番号管理画面での設定が上書きされます。",

    //自動発信リスト
    NotExistTelNo: "電話番号カラムが存在しません。ヘッダーを確認してください。",
    TelNoFormatErr: "不正な形式のため登録できません。電話番号形式で登録してください。",
    AutoCallListInUse: "この自動発信リストは使用中のため、削除できません。",
    DuplicateItemName: "重複した項目名は登録できません。",
    DuplicateTelno: "リスト内で重複した電話番号は登録できません。",
    Blankerr: "空白は登録できません。",
    ListDeleteError_PredictiveCall: "プレディクティブコール [タスク名：{0}]",

    //CSVインポート処理
    CsvUpload: "CSVファイルをドロップするか、クリックしてアップロードしてください。",
    NotCsvFile: "CSVファイルを選択してください。",
    FailedImportCsv: "CSV投入処理に失敗しました。",
    CsvNotAvailable: "CSV投入不可なデータがあります。修正後、再度CSV投入処理をしてください。",
    CsvTypeError: "CSV形式のファイルではありません。CSV形式のファイルをアップロードしてください。",
    CustomerCsvRowLimit: "1000行以下",
    CsvSizeLimit: "9MB以下",
    CsvSizeLimitAutoCallList: "5MB以下",
    CsvDuplicateItemName:
        "重複したヘッダーは投入できません。投入ファイルから以下のヘッダーを確認して下さい。",
    CsvTelnoRequired: "※電話番号ヘッダーは必須です。",
    CsvAddColumn: "※登録されていないヘッダーは追加登録されます。",
    FormatDownloadLink: "CSVテンプレートはこちらからダウンロードできます。",
    CsvDuplicateTelno: "この電話番号は既に登録されています。",
    CsvDuplicateTelnoInlist: "投入したCSVファイル内の電話番号が重複しています。",
    CsvImportError:
        "投入されたCSVファイルに以下のエラーが発生しました。CSVファイルを確認してください。",
    TableDataExchangeMessage: "*上記のオプションにチェックをつけると、既存のデータは削除されて上書きされます。",
    CsvTooManyRows: "CSVのデータ件数が上限を超えています。{0}以下の件数に設定し、再実施をお願いします。",
    CsvHeaderError: "CSVヘッダーの値が不正です。正しいヘッダーの値についてはテンプレートをダウンロードしてご確認ください。",
    CsvDataCountError: "CSVデータの列数が不正です。列数は{0}個を設定してください。",
    CsvRequiredError: "項目の記入漏れがあります。({0})",
    CsvNoteCount: "※1回で{0}件まで登録可能です。",
    CsvNoteRequiredField: "※必須入力項目は下記になります。",
    CsvNoteRules: "※以下のルール通りに設定をお願いします。",
    CsvNoteCharaLimit: "【{0}】 {1}文字以下",
    CsvNotePassword: "【{0}】 英小文字・数字の組み合わせ(8文字以上)/{1}文字以下",
    CsvFormatError: "不正な形式のため登録できません。({0})",
    CsvDuplicateErrorInDb: "この{0}は既に登録されています。({0}:{1})",
    CsvDuplicateErrorInCsv: "CSV内で{0}の重複があります。({0}:{1})",
    CsvSizeError: "{0}の文字数が上限を超えています。{1}文字以下に設定してください。",
    CsvNotRegisteredInfoError: "登録されてない{0}です。({0}:{1})",
    CsvRegisterSuccess: "全ての{0}登録に成功しました。({1}件)",
    CsvRegisterError: "{0}件中、以下{1}件の{2}登録に失敗しました。\r\nご確認の上、再登録をお願いいたします。",

    //音声アップロード処理
    AudioSizeLimit: "4.5MB未満",

    //モニタリング
    NoSelectedPbxError: "PBXを選択してください。",
    NoDataText: "データが存在しません。",
    NoSearchDateError: "検索日を設定してください。",
    SelectedDataError: "過去2ヶ月までの範囲で検索してください。",

    //集計
    DateSpanError: "開始日付は終了日付よりも前の日に設定してください。",
    TimeUnselectedError: "時間帯を選択してください。",
    GreaterThanOneMonthError: "1か月以内の期間で検索してください。",
    InoutTypeUnselectedError: "発着信区分を選択してください。",
    CallResultUnselectedError: "通話結果を選択してください。",
    NoStartDateError: "開始日を設定してください。",
    NoEndDateError: "終了日を設定してください。",
    DetailDownloadFailed: "詳細データダウンロードは1日で指定してください。",

    //通話品質分析
    CommentDeleteCheck: "本当にコメントを削除しますか?",
    CommentDeleted: "コメントが削除されました。",
    CommentCopied: "コメントがコピーされました。",
    CommentUpdated: "コメントが更新されました。",
    CommentInserted: "コメントが登録されました。",
    CommentInputPlaceholder: "コメントを入力してください。",
    NoCommentMessage: "コメントが存在しません。",
    VoiceTextBlankError: "音声テキストを入力してください。",
    VoiceTextCopied: "音声テキストがコピーされました。",
    VoiceTextUpdated: "音声テキストが更新されました。",
    NoVoiceMessage: "音声テキストが存在しません。",
    NoKeywordMessage: "検索したキーワードは存在しません。",
    NoKeywordDownload: "キーワードを指定してください。",
    CreateFileFailed: "ファイルの作成に失敗しました。",
    ConfirmMessage: "確認しますか?",
    UnconfirmMessage: "未確認に戻しますか？",
    MaxDownloadDataCount: "最大で1万件のダウンロードが可能です。",
    CallAnalysisTimeOut: "検索結果の取得に時間がかかっています。\n集計期間を短くするなど、取得件数を減らして再度実行してください。",
    CallAnalysis_FindDataError: "開始日及び終了日を入力してください。",
    ExtenalNumberSelectedError: "外線番号を選択してください。",
    NotOptionCreating: "(含まない)を作成する",
    CallContentDownloadMessage: [
        "検索条件にヒットする通話内容を一括作成します。",
        "大量のデータになるとファイル作成に時間を要します。",
        "ダウンロードの進捗は通話内容ダウンロード履歴より確認できます。"
    ],
    CallContentDownloadButtonMessage: "文字起こしされたデータをダウンロードします。",
    CallContentDownloadStartMessage: "ファイル作成を開始しました｡完了後お知らせいたします。\r\nまた､進捗はダウンロード履歴から確認できます｡",
    SearchCountLimitMessage: "表示可能な件数は1万件までです。条件を狭めて再検索してください(検索件数：{0})",

    //業務管理
    SkillSaveMessage: "編集内容は【保存】ボタンを押して適用してください。",
    SkillCsvImportError_Header: "ヘッダー情報に不正な値があります。ヘッダー情報は下記のテンプレートからご確認ください。\r\n",
    SkillCsvImportError_Unregistered: "登録されていないオペレーターIDです。IDを確認してください。\r\n位置：{0}\r\n",
    SkillCsvImportError_Required: "オペレーターID、もしくはスキルランクに空欄があります。オペレーターIDとスキルランクは必須項目です。\r\n位置：{0}\r\n",
    SkillCsvImportError_Max: "スキルランクは1以上、{0}以下の数字のみ入力できます。\r\n位置：{1}\r\n",
    SkillCsvImportError_Info: "\r\n    {0}行　オペレーターID：{1}",

    //音声管理
    AudioDeleteError:"この音声は以下の機能で設定されているため削除できません。",
    AudioDeleteError_External: "外線番号管理 [外線番号：{0}]",
    AudioDeleteError_Business: "営業時間管理 [営業カレンダー名：{0}]",
    AudioDeleteError_Irregular: "営業時間管理イレギュラー [営業カレンダー名：{0}]",
    AudioDeleteError_Script: "スクリプト管理 [スクリプト名：{0}]",

    //オペレーター管理
    UsernameExistsException: "既存ユーザーか過去に登録があるため、登録できませんでした。",
    InvalidParameterException: "メールアドレス または パスワード のフォーマットが正しくないため、登録できませんでした。",
    InvalidPasswordException: "英小文字・数字の組み合わせ(8文字以上)で入力してください。",
    NotAuthorizedException: "旧パスワードが違います。",

    OperatorCsvTooManyRows: "CSVインポート件数が",
    OperatorCsvIdFormatError: "オペレーターIDに全角文字が含まれています。",
    OperatorCsvIdSizeError: "オペレーターIDの文字数が上限を超えています。{0}文字以下に設定してください。",
    OperatorCsvNameSizeError: "オペレーター名の文字数が上限を超えています。{0}文字以下に設定してください。",
    OperatorCsvDuplicateError: "このオペレーターIDは既に登録されています。(オペレーターID:{0})",
    OperatorCsvPassWordFormatError: "パスワードのフォーマットが不正です。",
    OperatorCsvPassWordSizeError: "パスワードの文字数が上限を超えています。{0}文字以下に設定してください。",
    OperatorCsvDuplicateIdInCsv: "CSV内でオペレーターIDの重複があります。(オペレーターID:{0})",

    OperatorCsvRegisterSuccess: "全てのオペレーターID登録に成功しました。({0}件)",
    OperatorCsvRegisterError: "{0}件中、以下{1}件のオペレーターID登録に失敗しました。\r\nご確認の上、再登録をお願いいたします。",

    OperatorCsvNoteItem: "※全項目すべて設定が必要です。以下のルール通りに設定をお願いします。",
    OperatorCsvNoteId: "【{0}】 {1}文字以下/全角を含まない/オペレーター管理に未登録/CSV内で重複しない",
    OperatorCsvNoteAuth: "【{0}】 {1}のいずれか",

    //発信規制管理
    OutboundReject_HeaderError: "csvテンプレートをダウンロードして様式に合わせてお書きください。",
    OutboundReject_Upload_SizeLimit: "指定ファイルはサイズが大きすぎるため、アップロードできません。\n 10,000件以下のファイルを選択してください。 ",
    OutboundReject_CsvNoContent: "登録可能なデータがありません。CSVファイルの内容を確認してください。",
    OutboundReject_TelnoTypeError: "以下の番号の形は登録できません。",
    OutboundReject_CsvDuplicateError: "csvファイル内に以下の番号が重複しているため登録できません。",
    OutboundReject_DbDuplicateError: "以下の番号はすでに登録されているため、追加できません。",
    OutboundReject_MemoLimitError: "以下のメモの文字数は20文字以上のため、登録できません。20文字以内で記載してください。",

    //通話モニタリング
    CallMonitoring_FindDataError: "検索日時を入力してください。",

    //自動発信
    PredictiveCall_Start: "プレディクティブコールを発信しますか？",
    PredictiveCall_Stop: "プレディクティブコールを停止しますか？",

    // 通話品質分析集計
    DirectionDescription: "集計条件でユーザーを選択して集計すると、全ユーザーの平均または中央値を100%とした時の、各項目の偏り度合いを確認できます。",
    CallTimeDescription: "1応対あたりの通話時間の平均または中央値",
    SilenceDescription: "1分あたりの平均または中央値の沈黙回数です。沈黙はユーザーも取引先も発話しない時間が3秒以上存在した場合にカウントします。",
    TalkRateDescription: "Talk比率は、会話全体に占めるユーザーが発話していた時間の割合を示します。",
    TalkConflictDescription: "1分あたりの平均または中央値の被り回数です。被りはユーザーと取引先の発話が重なった時間が3秒間以上存在した場合にカウントします。",
    TalkSpeedDifferenceDescription: "取引先を基準とした会話速度の差です。値が小さいほど両者の会話速度が一致していることを示します。",
    RallyingDescription: "話者が切り替わった回数の1分あたりの平均または中央値です。",
    TalkSpeedDescription: "会話の速度を示します。単位は文字 / 秒です。",
    FillerDescription: "フィラーとは会話の合間に挟み込まれる言葉のことです。1分あたりの平均または中央値を表示します。",
    FrequencyDescription: "基本周波数(F0とも呼ばれます)は、音声の高低を表します。一般的には成人男性の場合、85-180Hz、成人女性の場合、165-255Hzになります。(注意：保留音が含まれる場合、正しく推定できない場合があります。)",
    FrequencyDifferenceDescription: [
        "取引先を基準とした基本周波数の差です。",
        "値が小さいほど両者の基本周波数が一致していることを示します。",
        "計算式：｜ユーザーの基本周波数 - 取引先の基本周波数｜/ 取引先の基本周波数",
        "値が小さい：取引先とユーザーの基本周波数が近い",
        "値が大きい：取引先とユーザーの基本周波数が離れている"
    ],
    AccentDescription: "音声の高低のばらつきを表します。音声に高い音と低い音のメリハリをつけて会話した場合、値が大きくなります。(注意：保留音が含まれる場合、正しく推定できない場合があります。)",
    AccentDifferenceDescription: "取引先を基準とした抑揚の差です。値が小さいほど両者の抑揚が一致していることを示します。",
    ScoreDescription: [
        "スコアは以下の式で0点から100点の間で計算されます。",
        "スコア=50",
        "+ 通話時間に応じた加減点",
        "+ (-1ｘ|Talk比率-40|)",
        "+ (被り回数/分ｘ-10)",
        "+ (沈黙回数/分ｘ-10)",
        ["1分未満", "0"],
        ["1分以上3分未満", "0"],
        ["3分以上5分未満", "0"],
        ["5分以上10分未満", "+30"],
        ["10分以上20分未満", "+50"],
        ["20分以上30分未満", "+50"],
        ["30分以上40分未満", "+30"],
        ["40分以上50分未満", "0"],
        ["50分以上60分未満", "-10"],
        ["60分以上", "-20"],
    ],
    OutboundRateDescription: "すべての発信における、相手が応答した通話件数の割合を示します。",
    InboundRateDescription: "すべての着信における、応答した通話件数の割合を示します。",
    CompareHighValueDescription: "{0}の{1}は、{2}{3}です。",

    // 通知
    NotNotificationMessage: "通知はありません。",

    // ダウンロード履歴管理
    ReDownloadMessage: "データの変動により前回と結果が異なる場合があります。",
    ErrorTextCopied: "エラー内容がコピーされました。",
    DownloadDeadline: "※ダウンロード有効期限は作成から24時間です。",

    // ブックマーク
    BookmarkRegister: "ブックマークに登録されました。",
    BookmarkDelete: "ブックマークが削除されました。"
};
