import React from "react";
import { Button, Row, Col, Glyphicon } from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import AscAudio from "../../containers/Elements/Audio/Audio";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import BookmarkModal from './BookmarkModal';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as GlobalConst from "../../components/AscConstants";
import ButtonSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidfaIcon from "@fortawesome/free-solid-svg-icons";
import * as regularfaIcon from "@fortawesome/free-regular-svg-icons";
import ASToast from "../Elements/Toast/Toast";
import SetButtonWithSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";

export default class Bookmark extends AscComponent {
    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            count: null,
            loading: false,
            bookmark_flag: {},
            bookmark_id: {},
            bookmark_btn_flag: {},

            // modal item
            show:               false,
            modalType:          null,
            download_link :     null,
            csvdata:[]              ,
            blocking:           false,
            download_interval_id:         null,
            download_end_flag:      false,
        };

        this.downloadFileType = this.getSelectOption('download_file_type', this.props.langText.SelectOption);
        this.downloadFileTypeNoMp3 = this.getSelectOption('download_file_type_no_mp3', this.props.langText.SelectOption);


        this.table = React.createRef();
        this.myAudio = document.createElement("AUDIO");
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    //画面遷移の時に polling処理を止める
    componentWillUnmount() {
        clearInterval(this.state.download_interval_id);
    }

    downloadData(path){
        const startDate = new Date();

        //5秒ごとに実行
        let download_interval = setInterval(()=> {
            this.ascAxios('get', this.baseURL + `/Download/s3CallHistoryDownload/${encodeURIComponent(path)}`)
                .then(res => {
                    let download_flag = true;
                    //サーバーから結果が処理中の場合（lockファイルがあるか、変換中か）
                    if (res.data != null && (res.data.download_status == "lock_exist" || res.data.download_status == "converting")) {
                        download_flag = false;
                        //サーバーから結果がエラーの場合
                    } else if (res.data != null && (res.data.download_status == "err" || res.data.download_status == "not_exist_path"|| res.data.download_status == "lambda_status_err")) {
                        throw new Error();
                    } else {
                        download_flag = true;
                    }
                    //成功場合
                    if (download_flag && res.config.url){
                        clearInterval(download_interval);
                        //ダウンロード中に時間がかかってbackgroundで動いた結果が戻ってきたら1回だけダウンロードさせる、
                        if (!this.state.download_end_flag) {
                            this.setState({download_end_flag: true});
                            this.toggleBlocking();
                            const downLoadLink = document.createElement("a");
                            downLoadLink.href = res.data;
                            downLoadLink.click();
                            downLoadLink.remove();
                        }
                    }
                    // 30秒タイムアウト
                    if (new Date() - startDate > 30 * 1000) {
                        clearInterval(download_interval);
                        this.toggleBlocking();
                        alert(this.getErrorString({code : "File_Download_Failed"}));
                    }
                })
                //失敗の場合
                .catch(err => {
                    clearInterval(download_interval);
                    this.toggleBlocking();
                    alert(this.getErrorString({code : "File_Download_Failed"}));
                })
        }, 3 * 1000);

        let {
            cm12_companies,
            cm13_departments,
            extension_number,
            inout_type,
            display_number,
            dest_tel_no,
            start_datetime,
            ct62_assgin_external_department_infos
        } = this.state.param.original;

        let externalInfo = ""
        if(display_number && ct62_assgin_external_department_infos.cm61_external_numbers.memo != "") {
            externalInfo = display_number + "(" + ct62_assgin_external_department_infos.cm61_external_numbers.memo + ")";
        } else if(display_number) {
            externalInfo = display_number;
        }
        this.saveAccessLogs({
            "resource" : `${this.reactContainerPath}/voiceLogDownload`, 
            "body" : {
                "voice_log_url" : path,
                "company_name" : cm12_companies.company_name,
                "department_name" : cm13_departments.department_name,
                "floor_name" : cm13_departments.floor_name || "",
                "extension_number" : extension_number,
                "inout_type" : inout_type,
                "display_number" : externalInfo,
                "dest_tel_no" : dest_tel_no,
                "start_datetime" : moment(start_datetime).format("YYYY-MM-DD HH:mm:ss"),
            }
        });

        this.setState({download_interval_id: download_interval});

    };
    // funcion_idでパラメータを持ってくる
    getUserCompanyControlParametersData(param) {
        let data = null
        if (this.props.userCompanyControlParameters.length > 0) {
            data = this.props.userCompanyControlParameters.find(function(item) {return item.function_id === param})
        }
        return data;
    }

    onPlayHandle = (audio) => {
        try {
            if (audio.played["length"] === 0 && audio.currentTime === 0) {
                this.saveAccessLogs({
                    "resource" : `${this.reactContainerPath}/voiceLogPlay`, 
                    "body" : {
                        "voice_log_url" : audio.currentSrc
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    getColumnsData() {
        let columns = [];
        // ID
        columns.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            sortable: false,
            width: this.props.boardWidth.id,
        });
        // 会社名
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            columns.push({
                Header: this.props.langText.Body.CompanyName,
                sortable: false,
                accessor: "cm12_companies.company_name",
                width: this.props.boardWidth.CallHistoryLarge,
            });
        }
        // 登録日
        columns.push({
            Header: this.props.langText.Body.Created,
            sortable: true,
            accessor: "created",
            width: this.props.boardWidth.large,
            Cell: data => {
                let created = data.value ? data.value : null;

                return created ? this.getMomentTime({ date: created, format: "YYYY/MM/DD HH:mm:ss" }) : "";
            }
        });
        // 発着区分
        columns.push({
            Header: this.props.langText.Body.InOutType,
            sortable: false,
            accessor: "inout_type",
            width: this.props.boardWidth.xxsmall,
        });
        // 内線番号
        columns.push({
            Header: this.props.langText.Body.ExtensionNumber,
            sortable: false,
            accessor: "extension_number",
            width: this.props.boardWidth.CallNumber,
        });
        // オペレーター名
        let operatorPermisson = {};
        if (this.props.userInfo.permission_json_data) {
            operatorPermisson = this.props.userInfo.permission_json_data.find(
                permission => permission.controller_id === "Operator"
            );
        }
        if (operatorPermisson.read) {
            columns.push({
                Header: this.props.langText.Body.OperatorName,
                sortable: false,
                accessor: "cm16_operators.operator_name",
                width: this.props.boardWidth.smedium,
                Cell: data => {
                    let returnValue = data.original && data.original.cm16_operators ? data.original.cm16_operators.operator_name : "---";
    
                    return returnValue;
                }
            });
        }
        // 外線番号
        columns.push({
            Header: this.props.langText.Body.ExternalNumber,
            sortable: false,
            accessor: "ct62_assgin_external_department_infos.cm61_external_numbers.display_number",
            width: this.props.boardWidth.CallHistoryLarge,
            Cell: data => {
                let returndata = "";
                if(data.value && data.row._original.ct62_assgin_external_department_infos.cm61_external_numbers.memo != "")
                {
                    returndata = data.value + "(" + data.row._original.ct62_assgin_external_department_infos.cm61_external_numbers.memo + ")";
                }
                else if(data.value)
                {
                    returndata = data.value;
                }
                return returndata;
            }
        });
        // 相手先番号
        columns.push({
            Header: this.props.langText.Body.DestUserNumber,
            sortable: false,
            accessor: "dest_tel_no",
            width: this.props.boardWidth.CallHistoryLarge,
        });
        // 開始時間
        columns.push({
            Header: this.props.langText.Body.StartTime,
            sortable: false,
            accessor: "start_datetime",
            width: this.props.boardWidth.large,
            Cell: data => {
                return data.value ? this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"}) : "";
            }
        });
        // 通話時間
        columns.push( {
            Header: this.props.langText.Body.TalkingTime,
            sortable: false,
            accessor: "billsec",
            width: this.props.boardWidth.xsmall,
            Cell: data => {
                return this.secToTime(data.value || 0);
            }
        });


        let call_record_operation_flag = true;
        let search_company_parameter_call_record = this.getUserCompanyControlParametersData("CALL_RECORD");

        let result_width = this.props.boardWidth.small;

        // 会社範囲以下は会社パラメタテーブルから操作フラグを決める
        if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
            && (search_company_parameter_call_record !== null &&
                typeof search_company_parameter_call_record !== "undefined")) {
            //コール履歴「操作」表示フラグ
            if (search_company_parameter_call_record.parameter_value === "N") {
                call_record_operation_flag = false;
            }
            result_width = this.props.boardWidth.medium;
        }

        // 結果
        columns.push({
            Header: this.props.langText.Body.Result,
            sortable: false,
            accessor: "disposition",
            width: result_width,
        });

        // 表示
        // システム管理者かパラメータがない場合
        // パラメータがあり、Yの場合
        if (call_record_operation_flag){
            // 操作
            const callHisotryPermission= this.props.userInfo.permission_json_data.find(
                (permission) => permission.controller_id === "CallHistory"
            );

            columns.push({
                Header: this.props.langText.Body.Control,
                accessor: "voice_log_url",
                sortable: false,
                width: this.props.boardWidth.smedium,
                Cell: data => {
                    let rowData = [];

                    if (data.value && data.row.disposition === "ANSWERED") {
                        if(callHisotryPermission.playback && data.value !== "expired") {
                            rowData.push(
                                <AscAudio
                                    key = {"audio" + data.index}
                                    type = "url"
                                    data = {data.value}
                                    className = "control-button"
                                    onPlayHandle={this.onPlayHandle}/>
                            );
                        } else if (callHisotryPermission.playback && data.value === "expired") {
                            rowData.push(<ButtonSpeechBubble
                                key = {"audio" + data.index}
                                bsSize="xsmall"
                                className="control-button-disabled"
                                DisplayIcon="play"
                                speechBubble={this.props.langText.Body.CallHistoryExpired}
                            />);
                        }
                        if(callHisotryPermission.export && data.value !== "expired") {
                            rowData.push(
                                <Button
                                    key={"dl-btn" + data.index}
                                    bsSize="xsmall"
                                    onClick={this.modalShow("download",data)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="download-alt"/>
                                </Button>
                            );
                        } else if (callHisotryPermission.export && data.value === "expired") {
                            rowData.push(<ButtonSpeechBubble
                                key = {"dl-btn" + data.index}
                                bsSize="xsmall"
                                className="control-button-disabled"
                                DisplayIcon="download-alt"
                                speechBubble={this.props.langText.Body.CallHistoryExpired}
                            />);
                        }
                        if(callHisotryPermission.playback && data.value !== "expired" && data.original.ct87_voice_log_to_text && data.original.ct86_call_result_eval && data.original.call_eval_flg === "Y") {
                            rowData.push(
                                <Button
                                    key={"search-btn" + data.index}
                                    bsSize="xsmall"
                                    onClick={() => {
                                        this.getCallHistoryAnalysis(data.row._original, data.index);
                                    }}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="search"/>
                                </Button>
                            );
                        } else if (callHisotryPermission.playback && data.value === "expired") {
                            rowData.push(<ButtonSpeechBubble
                                key = {"search-btn" + data.index}
                                bsSize="xsmall"
                                className="control-button-disabled"
                                DisplayIcon="search"
                                speechBubble={this.props.langText.Body.CallHistoryExpired}
                            />);
                        }
                    }
                    let flag = this.state.bookmark_flag[data.index] || this.state.bookmark_flag[data.index] === false
                            ? this.state.bookmark_flag[data.index] 
                            : data.row._original.ct03_id
                            ? true
                            : false;
                    let bookmark_id = null;

                    rowData.push(
                        <SetButtonWithSpeechBubble
                            key={"bookmark-btn" + data.index}
                            bsSize="xsmall"
                            onClick={async () => {
                                try {
                                    this.setState({
                                        bookmark_btn_flag: {
                                            ...this.state.bookmark_btn_flag,
                                            [data.index]: true
                                        }
                                    });
                                    let path = flag ? "Common/deleteBookmark" : "Common/insertBookmark";
                                    let body = flag ? {
                                        id: this.state.bookmark_id[data.index]
                                            ? this.state.bookmark_id[data.index]
                                            : data.row._original.ct03_id
                                    } : {
                                        ct60_id: data.row._original.id
                                    };
                                    let result = await this.ascAxios("post", path, body);
                                    if (result.data.id) bookmark_id = result.data.id;  

                                    this.toast(
                                        flag
                                        ? this.props.langText.Message.BookmarkDelete
                                        : this.props.langText.Message.BookmarkRegister
                                    );

                                    this.setState({
                                        bookmark_flag: {
                                            ...this.state.bookmark_flag,
                                            [data.index]: !flag
                                        },
                                        bookmark_id: {
                                            ...this.state.bookmark_id, 
                                            [data.index]: bookmark_id
                                        }
                                    });
                                } catch (err) {
                                    console.error(err);
                                    this.showErrorObjectMesssage(err);
                                } finally {
                                    this.setState({ 
                                        bookmark_btn_flag: {
                                            ...this.state.bookmark_btn_flag,
                                            [data.index]: false
                                        }
                                    });
                                }
                            }}
                            className="control-button"
                            disabled={this.state.bookmark_btn_flag[data.index] ? this.state.bookmark_btn_flag[data.index] : false}
                            DisplayFontAwesomeIcon={ flag ? solidfaIcon.faBookmark : regularfaIcon.faBookmark }
                            iconSize="1x"
                            speechBubble={ flag ? this.props.langText.Body.Bookmarked : this.props.langText.Body.BookmarkRegister}
                        />
                    );
                    return (
                        <Row className="text-center" key = {"row"+data.index}>
                            {rowData}
                        </Row>
                    );
                }
            });
        }


        return columns;
    }

    getAnalysisInfo(ct60_id, ct87_id) {
        return this.ascAxios('post', `${this.reactContainerPath}/getEvalDataByCt60Id`, {ct60_id, ct87_id});
    }
    
    getCallHistoryAnalysis = async (ct60_call_history_datas, idx) => {
        try {
            let ct87_voice_log_to_text_data = [];
            let ct86_call_result_eval_data = [];
            let ct03_bookmark_flag = null;
            
            if (this.state.bookmark_flag[idx]) {
                ct03_bookmark_flag = this.state.bookmark_id[idx]
            } else if (this.state.bookmark_flag[idx] === false) {
                ct03_bookmark_flag = this.state.bookmark_btn_flag[idx]
            } else {
                ct03_bookmark_flag = ct60_call_history_datas.ct03_id
            }

            if(ct60_call_history_datas){
                let res = await this.getAnalysisInfo(ct60_call_history_datas.id, ct60_call_history_datas.ct87_voice_log_to_text.id);
                ct87_voice_log_to_text_data = res.data.ct87_voice_log_to_text_data;
                ct86_call_result_eval_data = res.data.ct86_call_result_eval_data
            }
            let operator_talk_speed = ct86_call_result_eval_data.operator_talk_speed;
            let customer_talk_speed = ct86_call_result_eval_data.customer_talk_speed;
    
            const OP_SPEED_RATIO = (Math.abs((operator_talk_speed - customer_talk_speed) / customer_talk_speed) * 100).toFixed(1);
            const CUSTOMER_SPEED_RATIO = (Math.abs((customer_talk_speed - operator_talk_speed) / operator_talk_speed) * 100).toFixed(1);
            const OP_LABEL = this.props.langText.Body.Op;
            const CUSTOMER_LABEL = this.props.langText.Body.Customer;

            if (operator_talk_speed === 0 || customer_talk_speed === 0){
                ct86_call_result_eval_data.operator_speed_message = "";
                ct86_call_result_eval_data.customer_speed_message = "";
            } else if (operator_talk_speed > customer_talk_speed) {
                ct86_call_result_eval_data.operator_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedFast, CUSTOMER_LABEL, OP_SPEED_RATIO);
                ct86_call_result_eval_data.customer_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedSlow, OP_LABEL, CUSTOMER_SPEED_RATIO);
            } else if (operator_talk_speed < customer_talk_speed) {
                ct86_call_result_eval_data.operator_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedSlow, CUSTOMER_LABEL, OP_SPEED_RATIO);
                ct86_call_result_eval_data.customer_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedFast, OP_LABEL, CUSTOMER_SPEED_RATIO);
            } else {
                ct86_call_result_eval_data.operator_speed_message = "";
                ct86_call_result_eval_data.customer_speed_message = "";
            }



            let stateData = {
                ct60_call_history_datas,
                ct87_voice_log_to_text_data,
                ct86_call_result_eval_data,
                ct03_bookmark_flag,
                bookmark: true
            }

            this.props.historyPush({
                pathname: '/CallHistory/CallHistoryAnalysis',
                state: stateData
            });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    modalShow = (modalType, param) => event => {
        event.preventDefault()
        let setData = {
            param: param && param
                ? param
                :"",
            download_link: param && param.value
                ? param.value
                :"",
            downloadFileType: this.downloadFileType,
            downloadFileTypeNoMp3: this.downloadFileTypeNoMp3,
        }
        if (param) {
            setData.file_type = param.file_type
                ? param.file_type
                : this.downloadFileType[0].value

        }
        this.setState(setData);
        this.setState({show: true, modalType})

    }

    onClickHandle = modalType => event => {
        let {
            download_link,
            file_type,
        } = this.state

        switch (modalType) {
            case 'download':
                //ダウンロードする時に画面内で他の動作ができないようにする
                this.toggleBlocking();
                this.setState({download_end_flag: false});
                try{

                    let download_link_file_type = download_link.slice(0,-3).concat(file_type);
                    this.downloadData(download_link_file_type);
                    this.setState({
                        show: false
                    })
                } catch (e) {
                    alert(this.getErrorString(e.response.data));
                }
                break
            default:
                alert(this.getErrorString({code: 'modal'}))
        }
    }

    validationHandle = param => {
        let nowState = null
        let {
            file_type,
        } = this.state

        switch(param) {
            case "download":
                nowState = (
                    file_type )
                break
            default:
        }
        return nowState
    }

    render() {
        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <ASToast />
                <div className="Bookmark">
                    <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.Bookmark }]} />
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05">
                            <CommonTable
                                talbeRef={this.table}
                                style={{height: this.props.tableHeight}}
                                manual="manual"
                                columns={this.state.columns}
                                data={this.state.data}
                                pages={this.state.pages}
                                pageSize={this.state.pageSize}
                                loading={this.state.loading}
                                defaultSorted={[{id: "created", desc: true}]}
                                onFetchData={(state, instance) => {
                                    this.setState({ bookmark_flag: {}, bookmark_id: {} });
                                    this.fetchData(state, instance);
                                }}
                                filtered={this.state.filtered}
                                hidden_filtered={this.state.hidden_filtered}
                                previousText={this.props.langText.Table.PreviousText}
                                nextText={this.props.langText.Table.NextText}
                                loadingText={this.props.langText.Table.LoadingText}
                                noDataText={this.props.langText.Table.NoDataText}
                                rowsText={this.props.langText.Table.RowsText}/>
                        </Col>
                    </Row>
                    <BookmarkModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        onRadioChange = {this.onRadioChange}
                        validationHandle   = {this.validationHandle}
                    />
                </div>
            </BlockUi>
        );
    }
}
