import React from "react";
import AscComponent from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import InlineForm from "../Elements/FromGroup/InlineForm";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Overview from "./CallAnalysisSummaryElements/Overview";
import Compare from "./CallAnalysisSummaryElements/Compare";
import Summaries from "./CallAnalysisSummaryElements/Summaries";
import styled from "@emotion/styled";
import Menu from "./CallAnalysisSummaryElements/Menu";
import moment from "moment-timezone";
import BlockUi from "react-block-ui";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { on } from "events";
import { MultiSelect } from "react-multi-select-component";
import { v4 as uuidv4 } from 'uuid';

const SearchMenu = styled.div`
    width: ${(p) => p.width || 0}px;
    height: ${(p) => p.height || 0}px;
    position: fixed;
    right: 0;
    overflow-y: auto;
    display: flex;
    justify-content: center;

    transition: 0.5s;
    background-color: ${(p) => p.background || "#eef3f9"};
    box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
    border-radius: 4px;
    z-index: 1;
`;

export default class CallAnalysisSummary extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            data: [],
            loading: false,
            filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg ? "Y" : "N"
                    }
                },
                {
                    id: "start_datetime",
                    value: moment().subtract(30, 'days').startOf('day')
                },
                {
                    id: "end_datetime",
                    value: moment().endOf("day")
                },
                {
                    id: "summary_method",
                    value: "average"
                },
                {
                    id: "view_level",
                    value: "CallAnalysisSummaryOverview"
                }
            ],
            hidden_filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg ? "Y" : "N"
                    }
                },
                {
                    id: "start_datetime",
                    value: moment().subtract(30, 'days').startOf('day')
                },
                {
                    id: "end_datetime",
                    value: moment().endOf("day")
                },
                {
                    id: "summary_method",
                    value: "average"
                },
                {
                    id: "view_level",
                    value: "CallAnalysisSummaryOverview"
                }
            ],
            compare_filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg ? "Y" : "N"
                    }
                },
                {
                    id: "start_datetime",
                    value: moment().subtract(30, 'days').startOf('day')
                },
                {
                    id: "end_datetime",
                    value: moment().endOf("day")
                },
                {
                    id: "summary_method",
                    value: "average"
                },
                {
                    id: "view_level",
                    value: "CallAnalysisSummaryCompare"
                }
            ],
            compare_hidden_filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg ? "Y" : "N"
                    }
                },
                {
                    id: "start_datetime",
                    value: moment().subtract(30, 'days').startOf('day')
                },
                {
                    id: "end_datetime",
                    value: moment().endOf("day")
                },
                {
                    id: "summary_method",
                    value: "average"
                },
                {
                    id: "view_level",
                    value: "CallAnalysisSummaryCompare"
                }
            ],
            sorted: {id: "start_datetime", desc: true},
            csvdata: [],
            display_col: this.getSelectOption('call_analysis_summary_col_list', this.props.langText.SelectOption),
            display_col_list: this.getSelectOption('call_analysis_summary_col_list', this.props.langText.SelectOption),

            // 画面のビュー
            view: null,
            view_level: "CallAnalysisSummaryOverview",
            refresh_key: "",

            // メニュー
            menu_height: window.innerHeight - 105,
            menu_width: 0,
            menu_flag: false,
            accordion_flag: false,
            compare_accordion_flag: false,

            summary_period_select: this.getSelectOption('summary_period', this.props.langText.SelectOption),
            summary_period_selected: "previous_30_day",
            compare_summary_period_select: this.getSelectOption('summary_period', this.props.langText.SelectOption),
            compare_summary_period_selected: "previous_30_day",
            weekday_select: this.getSelectOption('weekday', this.props.langText.SelectOption),
            call_result_select: this.getSelectOption('call_result', this.props.langText.SelectOption),
            extension_number_select: [],
            operator_info_select: [],
            external_number_select: [],
            support_memo_select: [],
            company_select: [],
            compare_extension_number_select: [],
            compare_operator_info_select: [],
            compare_external_number_select: [],
            compare_support_memo_select: [],
            compare_company_select: [],
            csvTitle: "",
            floor_flg: this.props.userInfo.floor_flg ? "Y" : "N",
            call_result_filter_select: [],
            call_result_exclusion_select: [],
            compare_call_result_filter_select: [],
            compare_call_result_exclusion_select: [],
            call_result_filter: [],
            call_result_exclusion: [],
            compare_call_result_filter: [],
            compare_call_result_exclusion: [],
            compare_floor_flg: this.props.userInfo.floor_flg ? "Y" : "N",

            blocking: false,

            headers: [
                {
                    key: "key_as_string",
                    label: this.props.langText.Body.Date, //日付
                },
                {
                    key: "total_call_count.value",
                    label: this.props.langText.Body.TotalCallCnt, //総通話回数
                },
                {
                    key: "total_billsec.value",
                    label: this.props.langText.Body.TotalCallTime, //総通話時間
                },
                {
                    key: "total_score.value",
                    label: this.props.langText.Body.Score, //スコア
                },
                {
                    key: "billsec.value",
                    label: this.props.langText.Body.TalkingTime, //通話時間
                },
                {
                    key: "silence_count.value",
                    label: this.props.langText.Body.SilenceCount, //沈黙回数
                },
                {
                    key: "talk_per.value",
                    label: this.props.langText.Body.TalkRate, //Talk 比率
                },
                {
                    key: "talk_conflict_count.value",
                    label: this.props.langText.Body.TalkConflictCount, //被り回数
                },
                {
                    key: "operator_talk_speed.value",
                    label: this.props.langText.Body.TalkSpeed, //話速
                },
                {
                    key: "operator_talk_speed_diff.value",
                    label: this.props.langText.Body.TalkSpeedDifference, //話速の差
                },
                {
                    key: "rallying_count.value",
                    label: this.props.langText.Body.RallyingCount, //ラリー回数
                },
                {
                    key: "operator_frequency_average.value",
                    label: this.props.langText.Body.Frequency, //基本周波数
                },
                {
                    key: "operator_frequency_average_diff.value",
                    label: this.props.langText.Body.FrequencyDifference, //基本周波数の差
                },
                {
                    key: "operator_accent.value",
                    label: this.props.langText.Body.Accent, //抑揚
                },
                {
                    key: "operator_accent_diff.value",
                    label: this.props.langText.Body.AccentDifference, //抑揚の差
                }
            ]
        }
        this.csvLink = React.createRef();
        this.csvLinkCallResult = React.createRef();
    }
    
    // 初期設定
    componentDidMount = async () => {
        window.addEventListener('resize', this.resizeHandle);
        let compare_flag = false;
        let reset_flag = true;
        await this.getCompanyInfo();
        await this.getDeaprtmentInfo(compare_flag, reset_flag);
        await this.getOperatorInfo(compare_flag, reset_flag);
        await this.getExternalNumberInfo(compare_flag, reset_flag);
        await this.setCommonFloorSelect({departmentIds: this.props.userInfo.parent_department_id});
        await this.getCallResultInfo(compare_flag, this.props.userInfo.company_id, reset_flag);

        const result = await this.getCommonFloorSelect({
            departmentIds: this.props.userInfo.parent_department_id
        });

        this.setState({ 
            compare_floor_select: result.data, 
            compare_floor_id: result.data,
            display_col : this.state.display_col_list
        });

        await this.getExtensionNumberInfo(compare_flag, reset_flag);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.resizeHandle);
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    resizeHandle = () => {
        let {
            menu_flag,
            view_level
        } = this.state;

        let height = window.innerHeight - 105;
        let width = 0;

        if (menu_flag) {
            if (view_level === "CallAnalysisSummaryCompare") width = 800;
            else width = 400;
        }

        this.setState({
            menu_height: height,
            menu_width: width
        });
    }

    getCompanyInfo = async () => {
        try {
            const result = await this.getCommonCompanySelect(this.reactContainerPath.split("/")[1]);
            
            this.setState({
                company_select: result.data,
                compare_company_select: result.data
            });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    getDeaprtmentInfo = async (compare_flag, reset_flag) => {
        let container = {
            container: "/Summary",
            company_id: this.props.userInfo.company_id,
            floor_flg: this.props.userInfo.floor_flg
        };

        if (!compare_flag) await this.setCommonDepartmentSelect(container);

        if (compare_flag || reset_flag) {
            const result = await this.getCommonDepartmentSelect(container);
            this.setState({ 
                compare_department_select: result.data, 
                compare_department_id: result.data
            });
        }
    }

    getExtensionNumberInfo = async (compare_flag, reset_flag) => {
        let set_state = [];
        let company_id = this.getFilterData("cm12_id", compare_flag) ? this.getFilterData("cm12_id", compare_flag).value : 0;
        const floor_flg = this.getFilterData("cm12_id", compare_flag) ? this.getFilterData("cm12_id", compare_flag).floor_flg : 'N';
        const department_id = this.getFilterData("department_id", compare_flag) ? this.getFilterData("department_id", compare_flag).value : 0;
        let floor_id = this.getFilterData("floor_id", compare_flag) ? this.getFilterData("floor_id", compare_flag).value : 0;
        let cm13_id = null;
        
        if (company_id === 0) {
            this.setState(
                compare_flag ? {compare_extension_number_select: set_state} 
                    : {extension_number_select: set_state}
            );
            return;
        }

        if (floor_flg === 'Y' && department_id && !floor_id) {
            // 全フロア取得
            floor_id = compare_flag ? this.state.compare_floor_select.map(floors => floors.value) 
                : this.state.floorSelect.map(floors => floors.value);
        }

        if (Array.isArray(floor_id) || floor_id) {
            cm13_id = floor_id
        } else if (department_id) {
            cm13_id = department_id
        }

        try {
            const result = await this.ascAxios('post',`${this.reactContainerPath.split("/")[1]}/extensionNumberSelect`, {
                company_id,
                cm13_id
            });

            if (result.data.length > 0) set_state = result.data;

            if (!compare_flag) this.setState({ extension_number_select: set_state });
            if (compare_flag || reset_flag) this.setState({ compare_extension_number_select: set_state });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    getOperatorInfo = async (compare_flag, reset_flag) => {
        let set_state = [];
        let company_id = this.getFilterData("cm12_id", compare_flag) ? this.getFilterData("cm12_id", compare_flag).value : 0;
        const floor_flg = this.getFilterData("cm12_id", compare_flag) ? this.getFilterData("cm12_id", compare_flag).floor_flg : 'N';
        const department_id = this.getFilterData("department_id", compare_flag) ? this.getFilterData("department_id", compare_flag).value : 0;
        let floor_id = this.getFilterData("floor_id", compare_flag) ? this.getFilterData("floor_id", compare_flag).value : 0;
        let cm13_id = null;

        if (company_id === 0) {
            this.setState(
                compare_flag ? {compare_operator_info_select: set_state} 
                    : {operator_info_select: set_state}
            );
            return;
        }

        if (floor_flg === 'Y' && department_id && !floor_id) {
            // 全フロア取得
            floor_id = compare_flag ? this.state.compare_floor_select.map(floors => floors.value) 
                : this.state.floorSelect.map(floors => floors.value);
        }

        if (Array.isArray(floor_id) || floor_id) {
            cm13_id = floor_id
        } else if (department_id) {
            cm13_id = department_id
        }

        try {
            const result = await this.ascAxios('post', `${this.reactContainerPath.split("/")[1]}/getOperatorNameByCompanyId`, {
                company_id,
                cm13_id
            });

            if (result.data.length > 0) set_state = result.data;

            if (!compare_flag) this.setState({ operator_info_select: set_state });
            if (compare_flag || reset_flag) this.setState({ compare_operator_info_select: set_state});
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    getExternalNumberInfo = async (compare_flag, reset_flag) => {
        let set_state = [];
        let company_id = this.getFilterData("cm12_id", compare_flag) ? this.getFilterData("cm12_id", compare_flag).value : 0;
        const floor_flg = this.getFilterData("cm12_id", compare_flag) ? this.getFilterData("cm12_id", compare_flag).floor_flg : 'N';
        const department_id = this.getFilterData("department_id", compare_flag) ? this.getFilterData("department_id", compare_flag).value : 0;
        let floor_id = this.getFilterData("floor_id", compare_flag) ? this.getFilterData("floor_id", compare_flag).value : 0;
        let cm13_id = null;
        
        if (company_id === 0) {
            this.setState(
                compare_flag ? {compare_external_number_select: set_state} 
                    : {external_number_select: set_state}
            );
            return;
        }

        if (floor_flg === 'Y' && department_id && !floor_id) {
            // 全フロア取得
            floor_id = compare_flag ? this.state.compare_floor_select.map(floors => floors.value) 
                : this.state.floorSelect.map(floors => floors.value);
        }

        if (Array.isArray(floor_id) || floor_id) {
            cm13_id = floor_id
        } else if (department_id) {
            cm13_id = department_id
        }

        try {
            const result = await this.ascAxios('post', `${this.reactContainerPath.split("/")[1]}/getAllExternalNumberByPermissionAndCompanyId`, {
                company_id,
                cm13_id
            });

            if (result.data.length > 0) set_state = result.data;

            if (!compare_flag) this.setState({ external_number_select: set_state });
            if (compare_flag || reset_flag) this.setState({ compare_external_number_select: set_state });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    // コール結果条件取得
    getCallResultInfo = async (compare_flag, company_id, reset_flag) => {
        try {
            const result = await this.ascAxios('post', `${this.reactContainerPath.split("/")[1]}/getCallResultListByCompanyId`, {
                company_id
            });
            let default_select = {
                value: {cm64_id: 0, cm65_id: 0, level: 0, rank_type: 0},
                label: this.props.langText.SelectOption.CallResultUnRegistered
            }
            let body = reset_flag ? {
                call_result_filter_select: [default_select, ...result.data],
                call_result_exclusion_select: [default_select, ...result.data],
                compare_call_result_filter_select: [default_select, ...result.data],
                compare_call_result_exclusion_select: [default_select, ...result.data]
            } : (
                compare_flag ? {
                    compare_call_result_filter_select: [default_select, ...result.data],
                    compare_call_result_exclusion_select: [default_select, ...result.data]
                } : {
                    call_result_filter_select: [default_select, ...result.data],
                    call_result_exclusion_select: [default_select, ...result.data]
                }
            )
            
            

            this.setState(body);
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    // ビューモードボタン情報
    getViewModeInfo = () => {
        let callAnalysisSummaryViewModeList = this.props.langText.Body.CallAnalysisSummaryViewModeList;

        return (
            <ButtonGroup className="analysis-btn">
                {
                    Object.keys(callAnalysisSummaryViewModeList).map((row, index) => (
                        <Button
                            key={index}
                            onClick={(e) => {
                                this.onChange(e, "view_level");
                                this.onSummary({id: "start_datetime", desc: true});
                            }}
                            value={row}
                            className = {this.state.view_level === row ? "analysis-btn-focus" : ""}
                        >
                            {callAnalysisSummaryViewModeList[row]}
                        </Button>
                    ))
                }
            </ButtonGroup>
        );
    }

    menuHandle = () => {
        this.setState({
            menu_flag: !this.state.menu_flag
        }, () => {
            this.resizeHandle();
        });
    }

    getFilterData = (param, compare_flag) => {
        let my_param_data = null;
        if (compare_flag) my_param_data = this.state.compare_filtered.find(row => row.id === param); 
        else my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    onChange = (event, param, period_change, compare_flag) => {
        let my_filtered = null;
        if (compare_flag) my_filtered = this.state.compare_filtered;
        else my_filtered = this.state.filtered;

        let target_index = my_filtered.findIndex(row => row.id === param),
            value = "";
        
        if (event) value = event.target ? event.target.value : event;
        if (target_index !== -1) my_filtered.splice(target_index, 1);
        if ((param === "all_support" || param === "all_keyword") && target_index !== -1) value = !value;
        if (param === "call_result_filter" || param === "call_result_exclusion") {
            const compare = "compare_"
            let key = compare_flag ? `${compare}${param}` : param;
            
            // コール結果ランクが選択されたものに紐づくランクも一緒に選択される
            if (this.state.call_result_filter_select.length !== event.length && this.state[key].length < event.length) {
                let filter_data = event[event.length - 1];
                let associate_data = [];

                switch (filter_data.value.level) {
                    case 0:
                        associate_data = this.state.call_result_filter_select.filter(row => row.value.cm64_id === filter_data.value.cm64_id && row.value.level !== 0);
                        break;
                    case 1:
                        associate_data = this.state.call_result_filter_select.filter(row => 
                            row.value.rank_type === filter_data.value.rank_type && row.value.cm64_id === filter_data.value.cm64_id && row.value.level !== 1
                        );
                        break;
                    default:
                        break;
                }
                
                associate_data.forEach(row => {
                    event.push(row);
                });
                event = [...new Set(event)];
            } else if (this.state[key].length > event.length && event.length !== 0) {
                // コール結果ランクが除外されたものに紐づくランクも一緒に除外される
                let diff = this.state[key].filter(row => event.indexOf(row) === -1);
                let associate_data = null;

                switch (diff[0].value.level) {
                    case 0:
                        associate_data = event.filter(row => row.value.cm64_id !== diff[0].value.cm64_id);
                        break;
                    case 1:
                        associate_data = event.filter(row => 
                            (row.value.rank_type !== diff[0].value.rank_type || row.value.cm64_id !== diff[0].value.cm64_id) &&
                            (row.value.cm64_id !== diff[0].value.cm64_id || row.value.level !== 0)
                        );
                        break;
                    case 2:
                        associate_data = event.filter(row => 
                            (row.value.rank_type !== diff[0].value.rank_type ||
                            row.value.cm64_id !== diff[0].value.cm64_id ||
                            row.value.level !== 1) &&
                            (row.value.cm64_id !== diff[0].value.cm64_id || row.value.level !== 0)
                        );
                        break;
                    default:
                        break;
                }

                event = associate_data ? associate_data : event;
            }

            value = event;
            this.setState({[key]: event});
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }

        if ((param === "start_datetime" || param === "end_datetime") && period_change) {
            if (compare_flag) this.setState({ compare_summary_period_selected: "custom" });
            else this.setState({ summary_period_selected: "custom" });
        } else if (param === "view_level") {
            let temp_menu_width = value === "CallAnalysisSummaryCompare" ? 800 : 400;
            this.setState({
                view_level: value,
                sorted: {id: "start_datetime", desc: true},
                menu_width: this.state.menu_flag ? temp_menu_width : 0
            });
        }

        if (param === "cm12_id") {
            let operator_index = my_filtered.findIndex(row => row.id === "operator_selected_id");
            if (operator_index !== -1) my_filtered.splice(operator_index, 1);

            let extension_index = my_filtered.findIndex(row => row.id === "extension_number_selected");
            if (extension_index !== -1) my_filtered.splice(extension_index, 1);

            let external_index = my_filtered.findIndex(row => row.id === "external_number");
            if (external_index !== -1) my_filtered.splice(external_index, 1);

            let department_index = my_filtered.findIndex(row => row.id === "department_id");
            if(department_index !== -1) my_filtered.splice(department_index, 1);

            let floor_index = my_filtered.findIndex(row => row.id === "floor_id");
            if(floor_index !== -1) my_filtered.splice(floor_index, 1);

            let call_result_filter_index = my_filtered.findIndex(row => row.id === "call_result_filter");
            if (call_result_filter_index !== -1) my_filtered.splice(call_result_filter_index, 1);
            
            let call_result_exclusion_index = my_filtered.findIndex(row => row.id === "call_result_exclusion");
            if (call_result_exclusion_index !== -1) my_filtered.splice(call_result_exclusion_index, 1);
        }

        if (param === "department_id") {
            let floor_index = my_filtered.findIndex(row => row.id === "floor_id");
            if(floor_index !== -1) my_filtered.splice(floor_index, 1);

            let extension_index = my_filtered.findIndex(row => row.id === "extension_number_selected");
            if (extension_index !== -1) my_filtered.splice(extension_index, 1);

            let operator_index = my_filtered.findIndex(row => row.id === "operator_selected_id");
            if (operator_index !== -1) my_filtered.splice(operator_index, 1);

            let external_index = my_filtered.findIndex(row => row.id === "external_number");
            if (external_index !== -1) my_filtered.splice(external_index, 1);
        }

        if (param === "floor_id") {
            let operator_index = my_filtered.findIndex(row => row.id === "operator_selected_id");
            if (operator_index !== -1) my_filtered.splice(operator_index, 1);

            let extension_index = my_filtered.findIndex(row => row.id === "extension_number_selected");
            if (extension_index !== -1) my_filtered.splice(extension_index, 1);

            let external_index = my_filtered.findIndex(row => row.id === "external_number");
            if (external_index !== -1) my_filtered.splice(external_index, 1);
        }

        let returnValue = [];

        returnValue = my_filtered;

        if (compare_flag) this.setState({ compare_filtered: returnValue }); 
        else this.setState({ filtered: returnValue });
    }

    onTimeChange = (event, param, compare_flag) => {
        let max = 100;
        let value = event ? event.target.value : "";
        value = value.replace(/[^0-9]+/i, "");
        
        if (value !== "") {
            if (value.length > 1 && value.charAt(0) === "0") value = value.substr(1);
            Number(value);
        }
        switch (param) {
            case "start_ji":
            case "end_ji":
                max = 23;
                break;
            case "start_hun":
            case "call_start_hun":
            case "call_start_byo":
            case "end_hun":
            case "call_end_hun":
            case "call_end_byo":
                max = 59;
            default:
                break;
        }

        if (value > max) return;

        this.onChange(value, param, false, compare_flag);
    }

    onSelectChange = async (event, param, compare_flag) => {
        super.onSelectChange(event, param);

        let value = (event && event.value) ? event.value : "";

        switch (param) {
            case "summary_period_selected":
            case "compare_summary_period_selected":
                this.onPeriodChange(value, compare_flag);
                break;
            case "cm12_id":
                this.onChange(event, param, false, compare_flag);
                if(event && param !== null){
                    if (compare_flag) {
                        await this.getCallResultInfo(compare_flag, value);
                        const result = await this.getCommonDepartmentSelect({
                            container: "/Summary",
                            company_id: value,
                            floor_flg: event.floor_flg === "Y" ? "Y" : null
                        });
                        this.setState({ 
                            compare_department_select: result.data, 
                            compare_floor_flg: event.floor_flg,
                            compare_call_result_filter: [],
                            compare_call_result_exclusion: [],
                        });
                    } else {
                        await this.getCallResultInfo(compare_flag, value);
                        await this.setCommonDepartmentSelect({
                            container: "/Summary",
                            company_id: value,
                            floor_flg: event.floor_flg === "Y" ? "Y" : null
                        });
                        this.setState({
                            floor_flg: event.floor_flg,
                            call_result_filter: [],
                            call_result_exclusion: [],
                        });
                    }
                } else {
                    this.setState(
                        compare_flag ? { 
                                compare_department_select: [], 
                                compare_floor_select: [], 
                                compare_call_result_filter: [],
                                compare_call_result_exclusion: [],
                                compare_call_result_filter_select: [],
                                compare_call_result_exclusion_select: []
                            } 
                            : {
                                departmentSelect: [],
                                floorSelect: [],
                                call_result_filter: [],
                                call_result_exclusion: [],
                                call_result_filter_select: [],
                                call_result_exclusion_select: []
                            }
                    );
                }
                await this.getExtensionNumberInfo(compare_flag);
                await this.getOperatorInfo(compare_flag);
                await this.getExternalNumberInfo(compare_flag);
                break;
            case "department_id":
                this.onChange(event, param, false, compare_flag);
                if(event && param !== null){
                    if (compare_flag) {
                        const result = await this.getCommonFloorSelect({ departmentIds: value });
                        this.setState({ compare_floor_select: result.data });
                    } else {
                        await this.setCommonFloorSelect({ departmentIds: value });
                    }
                } else {
                    this.setState({floorSelect: []});
                }
                await this.getExtensionNumberInfo(compare_flag);
                await this.getOperatorInfo(compare_flag);
                await this.getExternalNumberInfo(compare_flag);
                break;
            case "floor_id":
                this.onChange(event, param, false, compare_flag);
                await this.getExtensionNumberInfo(compare_flag);
                await this.getOperatorInfo(compare_flag);
                await this.getExternalNumberInfo(compare_flag);
                break;
            default:
                break;
        }
    }

    onMultiSelectChange = (event, param, compare_flag) => {
        super.onMultiSelectChange(event, param);
        
        let value_arr = (event.length > 0) ? [] : "";
        event.forEach((row, index) => {
            value_arr[index] = row['value'];
        });

        this.onChange(value_arr, param, false, compare_flag);
    }

    onPeriodChange = (value, compare_flag) => {
        if (value === "custom") return;
        
        let start_datetime = moment().startOf("day");
        let end_datetime = moment().endOf("day");

        switch (value) {
            case "yesterday":
                start_datetime = moment().subtract(1, "days").startOf("day");
                end_datetime = moment().subtract(1, "days").endOf("day");
                break;
            case "this_week":
                start_datetime = moment().startOf("week");
                end_datetime = moment().endOf("week");
                break;
            case "last_week":
                start_datetime = moment().subtract(1, "week").startOf("week");
                end_datetime = moment().subtract(1, "week").endOf("week");
                break;
            case "previous_2_week":
                start_datetime = moment().subtract(2, "week").startOf("week");
                end_datetime = moment().subtract(2, "week").endOf("week");
                break;
            case "previous_3_week":
                start_datetime = moment().subtract(3, "week").startOf("week");
                end_datetime = moment().subtract(3, "week").endOf("week");
                break;
            case "previous_4_week":
                start_datetime = moment().subtract(4, "week").startOf("week");
                end_datetime = moment().subtract(4, "week").endOf("week");
                break;
            case "previous_5_week":
                start_datetime = moment().subtract(5, "week").startOf("week");
                end_datetime = moment().subtract(5, "week").endOf("week");
                break;
            case "previous_6_week":
                start_datetime = moment().subtract(6, "week").startOf("week");
                end_datetime = moment().subtract(6, "week").endOf("week");
                break;
            case "previous_30_day":
                start_datetime = moment().subtract(30, 'days').startOf('day');
                break;
            case "this_month":
                start_datetime = moment().startOf('month');
                end_datetime = moment().endOf('month');
                break;
            case "last_month":
                start_datetime = moment().subtract(1, 'month').startOf('month');
                end_datetime = moment().subtract(1, 'month').endOf('month');
                break;
            case "previous_2_month":
                start_datetime = moment().subtract(2, 'months').startOf('month');
                end_datetime = moment().subtract(2, 'months').endOf('month');
                break;
            case "previous_3_month":
                start_datetime = moment().subtract(3, 'months').startOf('month');
                end_datetime = moment().subtract(3, 'months').endOf('month');
                break;
            case "now_quarter":
                start_datetime = moment().startOf('quarter');
                end_datetime = moment().endOf('quarter');
                break;
            case "previous_quarter":
                start_datetime = moment().subtract(1, 'quarter').startOf('quarter');
                end_datetime = moment().subtract(1, 'quarter').endOf('quarter');
                break;
            default:
                break;
        }

        this.onChange(start_datetime, "start_datetime", false, compare_flag);
        this.onChange(end_datetime, "end_datetime", false, compare_flag);
    }

    onClickAccordion = (event, compare_flag) => {
        if (compare_flag) this.setState({ compare_accordion_flag: !this.state.compare_accordion_flag });
        else this.setState({ accordion_flag: !this.state.accordion_flag });
    }

    validationHandle = (param, compare_flag) => {
        let nowState = true,
            start_ji = this.getFilterData("start_ji", compare_flag) ? Number(this.getFilterData("start_ji", compare_flag)) : "",
            start_hun = this.getFilterData("start_hun", compare_flag) ? Number(this.getFilterData("start_hun", compare_flag)) : "",
            end_ji = this.getFilterData("end_ji", compare_flag) ? Number(this.getFilterData("end_ji", compare_flag)) : "",
            end_hun = this.getFilterData("end_hun", compare_flag) ? Number(this.getFilterData("end_hun", compare_flag)) : "",
            call_start_hun = this.getFilterData("call_start_hun", compare_flag) ? Number(this.getFilterData("call_start_hun", compare_flag)) : "",
            call_start_byo = this.getFilterData("call_start_byo", compare_flag) ? Number(this.getFilterData("call_start_byo", compare_flag)) : "",
            call_end_hun = this.getFilterData("call_end_hun", compare_flag) ? Number(this.getFilterData("call_end_hun", compare_flag)) : "",
            call_end_byo = this.getFilterData("call_end_byo", compare_flag) ? Number(this.getFilterData("call_end_byo", compare_flag)) : "",
            min_score = this.getFilterData("min_score", compare_flag) ? Number(this.getFilterData("min_score", compare_flag)) : "",
            max_score = this.getFilterData("max_score", compare_flag) ? Number(this.getFilterData("max_score", compare_flag)) : "",
            min_talk_rate = this.getFilterData("min_talk_rate", compare_flag) ? Number(this.getFilterData("min_talk_rate", compare_flag)) : "",
            max_talk_rate = this.getFilterData("max_talk_rate", compare_flag) ? Number(this.getFilterData("max_talk_rate", compare_flag)) : "",
            min_speech_speed = this.getFilterData("min_speech_speed", compare_flag) ? Number(this.getFilterData("min_speech_speed", compare_flag)) : "",
            max_speech_speed = this.getFilterData("max_speech_speed", compare_flag) ? Number(this.getFilterData("max_speech_speed", compare_flag)) : "";

        switch(param) {
            case "start_ji":
            case "end_ji":
            case "start_hun":
            case "end_hun":
                nowState = !(
                    (start_ji && (end_ji || end_ji === 0)) &&
                    (start_ji > end_ji)
                );
            
                nowState = nowState ? 
                    !(
                        (((start_ji && end_ji) && (start_ji === end_ji)) || (!start_ji && !end_ji)) &&
                        (start_hun && (end_hun || end_hun === 0)) &&
                        (start_hun > end_hun)
                    ) : false;
                break;
            case "call_start_hun":
            case "call_end_hun":
            case "call_start_byo":
            case "call_end_byo":
                nowState = !(
                    (call_start_hun && (call_end_hun || call_end_hun === 0)) &&
                    (call_start_hun > call_end_hun)
                );
                
                nowState = nowState ? 
                    !(
                        (((call_start_hun && call_end_hun) && (call_start_hun === call_end_hun)) || (!call_start_hun && !call_end_hun)) &&
                        (call_start_byo && (call_end_byo || call_end_byo === 0)) &&
                        (call_start_byo > call_end_byo)
                    ) : false;
                break;
            case "min_score":
            case "max_score":
                nowState = !(
                    (min_score && (max_score || max_score === 0)) &&
                    (min_score > max_score)
                );
                break;
            case "min_talk_rate":
            case "max_talk_rate":    
                nowState = !(
                    (min_talk_rate && (max_talk_rate || max_talk_rate === 0)) &&
                    (min_talk_rate > max_talk_rate)
                );
                break;
            case "min_speech_speed":
            case "max_speech_speed":
                nowState = !(
                    (min_speech_speed && (max_speech_speed || max_speech_speed === 0)) &&
                    (min_speech_speed > max_speech_speed)
                );
                break;
            case "summary":
                nowState = !(
                    ((start_ji && (end_ji || end_ji === 0)) && (start_ji > end_ji)) ||
                    (
                        (((start_ji && end_ji) && (start_ji === end_ji)) || (!start_ji && !end_ji)) &&
                        (start_hun && (end_hun || end_hun === 0)) &&
                        (start_hun > end_hun)
                    ) ||
                    ((call_start_hun && (call_end_hun || call_end_hun === 0)) && (call_start_hun > call_end_hun)) ||
                    (
                        (((call_start_hun && call_end_hun) && (call_start_hun === call_end_hun)) || (!call_start_hun && !call_end_hun)) &&
                        (call_start_byo && (call_end_byo || call_end_byo === 0)) &&
                        (call_start_byo > call_end_byo)
                    ) ||
                    ((min_score && (max_score || max_score === 0)) && (min_score > max_score)) ||
                    ((min_talk_rate && (max_talk_rate || max_talk_rate === 0)) && (min_talk_rate > max_talk_rate)) ||
                    ((min_speech_speed && (max_speech_speed || max_speech_speed === 0)) && (min_speech_speed > max_speech_speed)) ||
                    (compare_flag && !this.validationHandle(param))
                );
                break;
            default:
                break;
        }

        return nowState;
    }

    onClear = async () => {
        let filter = [...this.state.filtered];
        let view_level = filter.find(row => row.id === "view_level").value;

        let my_filtered = [
            {
                id: "inout_type",
                value: "all"
            },
            {
                id: "cm12_id",
                value: {
                    value: this.props.userInfo.company_id, 
                    label:this.props.userInfo.company_name, 
                    floor_flg: this.props.userInfo.floor_flg ? "Y" : "N"
                }
            },
            {
                id: "start_datetime",
                value: moment().subtract(30, 'days').startOf('day')
            },
            {
                id: "end_datetime",
                value: moment().endOf("day")
            },
            {
                id: "summary_method",
                value: "average"
            },
            {
                id: "view_level",
                value: view_level
            }
        ];

        let compare_filtered = [
            {
                id: "inout_type",
                value: "all"
            },
            {
                id: "cm12_id",
                value: {
                    value: this.props.userInfo.company_id, 
                    label:this.props.userInfo.company_name, 
                    floor_flg: this.props.userInfo.floor_flg ? "Y" : "N"
                }
            },
            {
                id: "start_datetime",
                value: moment().subtract(30, 'days').startOf('day')
            },
            {
                id: "end_datetime",
                value: moment().endOf("day")
            },
            {
                id: "summary_method",
                value: "average"
            },
            {
                id: "view_level",
                value: "CallAnalysisSummaryCompare"
            }
        ];

        this.setState({
            filtered: my_filtered,
            summary_period_selected: "previous_30_day",
            compare_filtered,
            compare_summary_period_selected: "previous_30_day",
            call_result_filter: [],
            call_result_exclusion: [],
            compare_call_result_filter: [],
            compare_call_result_exclusion: [],
        }, async () => {
            await this.getCompanyInfo();
            let compare_flag = false;
            let reset_flag = true;
            await this.getDeaprtmentInfo(compare_flag, reset_flag);
            await this.getOperatorInfo(compare_flag, reset_flag);
            await this.getExternalNumberInfo(compare_flag, reset_flag);
            await this.getCallResultInfo(compare_flag, this.props.userInfo.company_id, reset_flag);

            this.setState({ 
                floor_flg: this.props.userInfo.floor_flg ? "Y" : "N", 
                compare_floor_flg: this.props.userInfo.floor_flg ? "Y" : "N"
            });
            await this.getExtensionNumberInfo(compare_flag, reset_flag);
        });
    }

    onSummary = async(sort) => {
        let {
            filtered,
            compare_filtered,
            sorted
        } = this.state;
        let compare_filter = [];
        let randomId = ""; 

        this.setState({
            loading: true
        });
        
        let filter = [...filtered];
        let view_level = filter.find(row => row.id === "view_level").value;

        let set_state_obj = {
            hidden_filtered: filter
        }

        if (view_level === "CallAnalysisSummaryCompare") {
            compare_filter = [...compare_filtered];
            set_state_obj.compare_hidden_filtered = compare_filter;
        }

        if (sort) {
            sorted = sort;
        }

        try {
            this.blockUI();
            this.setState(
                set_state_obj, async () => {
                let result = "";
                switch (view_level) {
                    case "CallAnalysisSummaryOverview":
                        result = await this.ascAxios('post', `${this.reactContainerPath}/overview`, {
                            filtered: this.state.hidden_filtered,
                            compare_flag: false,
                        });
                        break;
                    case "CallAnalysisSummaryByExtensionNumber":
                    case "CallAnalysisSummaryByOperator":
                    case "CallAnalysisSummaryByDay":
                    case "CallAnalysisSummaryByWeek":
                    case "CallAnalysisSummaryByMonth":
                        result = await this.ascAxios('post', `${this.reactContainerPath}/summary`, {
                            filtered: this.state.hidden_filtered,
                            sorted
                        });
                        randomId = uuidv4(); //共用コンポーネント(Summaries.js)を更新するためランダムIDを取得
                        break;
                    case "CallAnalysisSummaryCompare":
                        let compare_a_result = await this.ascAxios('post', `${this.reactContainerPath}/overview`, {
                            filtered: this.state.hidden_filtered,
                            sorted
                        });
                        let compare_b_result = await this.ascAxios('post', `${this.reactContainerPath}/overview`, {
                            filtered: this.state.compare_hidden_filtered,
                            sorted,
                            compare_flag: true,
                            compare_filterd: this.state.hidden_filtered,
                        });
                        result = {
                            "data" : [
                                compare_a_result.data, 
                                compare_b_result.data
                            ]
                        };
                        break;
                    default:
                        break;
                }
                if ((view_level === this.state.view_level) && result && result.data) {
                    this.setState({
                        data: result.data,
                        loading: false,
                        refresh_key: randomId
                    });
                }
            });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        } finally {
            // this.unblockUI();
            this.setState({
                loading: false
            });
        }
    }

    DownloadData = (category) => {
        try {
            this.blockUI();
            let csv_title = null;
            switch (category) {
                case "call_result":
                    csv_title = 
                        moment().utc().add(9, "h").format("YYYYMMDD") +
                        "_call_memo";
                    break;
                default:
                    csv_title =
                        moment().utc().add(9, "h").format("YYYYMMDD") +
                        "_heatmap_" +
                        this.getFilterData("summary_method") +
                        "_number_of_call";
                    break;
            }
            
            

            this.setState({
                csvTitle: csv_title
            }, () => {
                category === "call_result" ? 
                    this.csvLinkCallResult.current.link.click() :
                    this.csvLink.current.link.click()
            });
        } catch (err) {
            console.log(err);
            this.showErrorObjectMesssage(err);
        } finally {
            setTimeout(() => {
                this.unblockUI();
            }, 500);
        }
    }

    createDownloadData = (level, category) => {
        let {
            data
        } = this.state;

        try {
            let csv_data = [];
            switch (category) {
                case "call_result":
                    let call_result_header = ["大項目", "ランク", "詳細項目", "件数", "出現率"];
                    
                    csv_data.push(call_result_header);

                    if (data && data.call_result && data.call_result.data) {
                        // 大項目
                        data.call_result.data.forEach(row => {
                            csv_data.push([row.name, "-", "-", row.count, `${row.percentage}${this.props.langText.Body.Percent}`]);
    
                            // ランク
                            row.children.forEach(rank => {
                                csv_data.push([row.name, rank.name, "-", rank.count, `${rank.percentage}${this.props.langText.Body.Percent}`]);
    
                                // コール結果詳細項目
                                rank.children.forEach(child => {
                                    csv_data.push([row.name, rank.name, child.name, child.count, `${child.percentage}${this.props.langText.Body.Percent}`]);
                                });
                            });
                        });
                    }
                    break;
            
                default:
                    let hours = [];
                    let header = ["通話時間帯", "日曜", "月曜", "火曜", "水曜", "木曜", "金曜", "土曜"];

                    for (let i = 0; i < 24; i++) {
                        let hour =  i + ":00";
                        hours.push(hour); 
                    }
                    
                    csv_data.push(header);

                    hours.forEach((hour, index) => {
                        let data_arr = [];

                        data_arr.push(hour);

                        if (data && data.heatmap) {
                            data.heatmap.forEach(map_data => {
                                let level_data = 0;
            
                                if (level === "all") level_data = map_data.value[index].all;
                                else if (level === "out") level_data = map_data.value[index].out;
                                else if (level === "in") level_data = map_data.value[index].in;
            
                                if (this.hasDecimal(level_data)) level_data = level_data.toFixed(1);

                                data_arr.push(level_data);
                            });
                        }

                        csv_data.push(data_arr);
                    });
                    break;
            }
            

            return csv_data;
        } catch (err) {
            console.log(err);
            this.showErrorObjectMesssage(err);
        }
    }

    hasDecimal = (num) => {
        return num.toString().includes('.');
    }

    getCsvFileName() {
        let date = this.getFilterData("start_datetime");
        let file_name = "";
        if (!!date) {
            file_name = moment(this.getFilterData("start_datetime")).format("YYYYMMDD") + "_";
        }
        let nowTime = moment().utc().add(9, 'h').format("YYYYMMDDhhmmss");
        let viewModeList = this.props.langText.Body.CallAnalysisSummaryViewModeList;
        let viewLevel = viewModeList[this.state.view_level];

        return file_name + this.props.langText.Body.CallAnalysisSummary + "_" + 
                viewLevel + "_" + nowTime + ".csv";
    }

    callAnalysisSummaryCsvDL = async () => {
        try {
            this.unblockUI();
            let result = await this.ascAxios('post', `${this.reactContainerPath}/summary`, {
                filtered: this.state.hidden_filtered,
                sorted: this.state.sorted
            });
            if(result.data.length === 0){
                return this.showErrorObjectMesssage({message: "Download_Failed"});
            } else {
                let headers = this.state.headers;
                if (this.state.view_level === "CallAnalysisSummaryByOperator") {
                    headers = headers.map((item) => item.key === "key_as_string" 
                        ? { ...item, label: this.props.langText.Body.OperatorName} : item);
                } else if (this.state.view_level === "CallAnalysisSummaryByExtensionNumber") {
                    headers = headers.map((item) => item.key === "key_as_string" 
                        ? { ...item, label: this.props.langText.Body.ExtensionNumber} : item);
                } else {
                    headers = headers.map((item) => item.key === "key_as_string" 
                        ? { ...item, label: this.props.langText.Body.Date} : item);
                }
                let csvData = this.csvDataFactory(result.data);
                await this.setState({
                    csvdata: csvData,
                    headers
                });
                return this.csvLink.current.link.click();
            }
        } catch (e) {
            return this.showErrorObjectMesssage(e, "DataSelectError");
        } finally {
            this.unblockUI();
        }
    }

    addZero(num) {
        return ((num < 10) ? '0' : '') + num
    }

    csvDataFactory(dataArr) {
        try {
            dataArr.forEach(data => {
                Object.keys(data).forEach(key => {
                    let display_data = "";
                    switch(key) {
                        case "key_as_string":
                            if (data[key] === "average") {
                                data[key] = this.props.langText.Body.AverageValue;
                            } else if (data[key] === "median") {
                                data[key] = this.props.langText.Body.MedianValue;
                            } else if (data[key] !== "average" && data[key] !== "median") {
                                switch (this.state.view_level) {
                                    case "CallAnalysisSummaryByExtensionNumber":
                                        if (data[key] === "none") {
                                            data[key] = this.props.langText.Body.ExtensionNumberNotFound;
                                        }
                                        break;
                                    case "CallAnalysisSummaryByOperator":
                                        if (data[key] === "none") {
                                            data[key] = this.props.langText.Body.OperatorNotFound;
                                        }
                                        break;
                                    case "CallAnalysisSummaryByDay":
                                        data[key] = moment(data[key]).format("YYYY-MM-DD");
                                        break;
                                    case "CallAnalysisSummaryByWeek":
                                        data[key] = `${moment(data[key]).format("YYYY-MM-DD")} ~ ${moment(data[key]).add(6, "days").format("YYYY-MM-DD")}`;
                                        break;
                                    case "CallAnalysisSummaryByMonth":
                                        data[key] = `${moment(data[key]).format("YYYY-MM")}`;
                                        break;
                                }
                            }
                            break;
                        case "total_call_count": 
                        case "silence_count":
                        case "talk_conflict_count": 
                        case "rallying_count":
                            if (data[key].value) {
                                display_data = parseFloat(data[key].value.toFixed(2)).toLocaleString();
                            } else {
                                display_data = 0;
                            }
                            data[key].value = display_data + ` ${this.props.langText.Body.TimeCount}`;
                            break;
                        case "total_billsec":
                        case "billsec":
                            if (data[key].value) {
                                let hour = parseInt(data[key].value / 3600);
                                let min = parseInt((data[key].value % 3600) / 60);
                                let sec = parseInt(data[key].value % 60);
                                display_data = this.addZero(hour) + ":" + this.addZero(min) + ":" + this.addZero(sec);
                            } else {
                                display_data = "00:00:00";
                            }
                            data[key].value = display_data;
                            break;
                        case "total_score":
                            if (data[key].value) {
                                display_data = parseInt(data[key].value).toLocaleString();
                            } else {
                                display_data = 0;
                            }
                            data[key].value = display_data + this.props.langText.Body.ScoreAll;
                            break;
                        case "talk_per":
                            if (data[key].value) {
                                display_data = parseFloat(data[key].value.toFixed(2)).toLocaleString();
                            } else {
                                display_data = 0;
                            }
                            data[key].value = display_data + " %";
                            break;
                        case "operator_talk_speed":
                            if (data[key].value) {
                                display_data = parseFloat(data[key].value.toFixed(2)).toLocaleString();
                            } else {
                                display_data = 0;
                            }
                            data[key].value = display_data + ` ${this.props.langText.Body.CharactersPerSecond}`
                            break;
                        case "operator_talk_speed_diff":
                            if (data[key].value && data[key].value != "NaN" && isFinite(data[key].value)) {
                                display_data = parseFloat(data[key].value.toFixed(2)).toLocaleString();
                            } else {
                                display_data = 0;
                            }
                            data[key].value = display_data;
                            break;
                        case "operator_frequency_average":
                            if (data[key].value) {
                                display_data = parseFloat(data[key].value.toFixed(2)).toLocaleString();
                            } else {
                                display_data = 0;
                            }
                            data[key].value = display_data + " Hz";
                            break;
                        case "operator_frequency_average_diff":
                        case "operator_accent":
                        case "operator_accent_diff":
                            if (data[key].value) {
                                display_data = parseFloat(data[key].value.toFixed(2)).toLocaleString();
                            } else {
                                display_data = 0;
                            }
                            data[key].value = display_data;
                            break;
                    }
                }) 
            });

            return dataArr;
        } catch(err) {
            console.error(err);
        }
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <Col xs={12} sm={12} className="padding-0">
                    <SetBreadCrumb
                        displayItems={[
                            { link: "/Summary", name: this.props.langText.Body.Summary },
                            { name: this.props.langText.Body.CallAnalysisSummary },
                        ]}
                    />
                        <Row className="margin-bottom-20">
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Col className="view_mode pull-left">
                                    {this.getViewModeInfo()}
                                </Col>
                                {(this.state.view_level === "CallAnalysisSummaryByOperator" || this.state.view_level === "CallAnalysisSummaryByDay" ||
                                    this.state.view_level === "CallAnalysisSummaryByWeek" || this.state.view_level === "CallAnalysisSummaryByMonth" ||
                                    this.state.view_level === "CallAnalysisSummaryByExtensionNumber") &&
                                    <Col xs={3} sm={3} md={3} lg={3} className="csv_download pull-right padding-right-0 margin-left-05">
                                        <Col xs={2} sm={2} md={2} lg={2} className="padding-right-0 margin-top-05">
                                            <Button
                                                bsStyle="default"
                                                bsSize = "xsmall"
                                                onClick={this.callAnalysisSummaryCsvDL}>
                                                <FontAwesomeIcon icon={faCloudDownloadAlt} />
                                            </Button>
                                            <CSVLink
                                                data={this.state.csvdata}
                                                headers={this.state.headers}
                                                filename={this.getCsvFileName()}
                                                className="hidden"
                                                ref={this.csvLink}
                                                />
                                        </Col>
                                        <Col xs={10} sm={10} md={10} lg={10} className="padding-right-0">
                                            <MultiSelect
                                                key="display_col"
                                                hasSelectAll={true}
                                                options={this.state.display_col_list}
                                                value = {this.state.display_col}
                                                onChange={(e) => {
                                                    this.setState({
                                                        display_col: e,
                                                        refresh_key: e
                                                    });
                                                }}
                                                overrideStrings={{
                                                    selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                                    allItemsAreSelected: this.props.langText.Body.AllSelected,
                                                    selectAll: this.props.langText.MultiSelect.AllSelect,
                                                    search: this.props.langText.Body.Search,
                                                }}
                                            />
                                        </Col>
                                    </Col>
                                }
                            </Col>
                        </Row>
                    <div className="view">
                        {this.state.view_level === "CallAnalysisSummaryOverview" && 
                            <Overview
                                state={this.state}
                                data={this.state.data}
                                langText={this.props.langText}
                                csvTitle={this.state.csvTitle} 
                                csvLink={this.csvLink} 
                                csvLinkCallResult={this.csvLinkCallResult}
                                onSummary={this.onSummary}
                                DownloadData={this.DownloadData}
                                createDownloadData={this.createDownloadData} 
                            />}
                        {(this.state.view_level === "CallAnalysisSummaryByOperator" || this.state.view_level === "CallAnalysisSummaryByDay" ||
                            this.state.view_level === "CallAnalysisSummaryByWeek" || this.state.view_level === "CallAnalysisSummaryByMonth" ||
                            this.state.view_level === "CallAnalysisSummaryByExtensionNumber") &&
                            <Summaries
                                key={this.state.refresh_key}
                                propSetState={this.propSetState}
                                onSummary={this.onSummary}
                                boardWidth={this.props.boardWidth}
                                langText={this.props.langText}
                                state={this.state}
                                data={this.state.data}
                            />}
                        {this.state.view_level === "CallAnalysisSummaryCompare" &&
                            <Compare
                                state={this.state}
                                data={this.state.data}
                                langText={this.props.langText}
                                csvTitle={this.state.csvTitle} 
                                csvLink={this.csvLink} 
                                onSummary={this.onSummary}
                                DownloadData={this.DownloadData}
                                createDownloadData={this.createDownloadData}
                                getFilterData={this.getFilterData}
                            />}
                    </div>
                </Col>
                <Col xs={0} sm={0}>
                    <SearchMenu
                        width={this.state.menu_width}
                        height={this.state.menu_height}>
                        <Menu
                            state={this.state}
                            userInfo={this.props.userInfo}
                            langText={this.props.langText}
                            getFilterData={this.getFilterData}
                            onChange={this.onChange}
                            onClickAccordion={this.onClickAccordion}
                            validationHandle={this.validationHandle}
                            onKeyDownIntCheck={this.onKeyDownIntCheck}
                            onSelectChange={this.onSelectChange}
                            onMultiSelectChange={this.onMultiSelectChange}
                            onTimeChange={this.onTimeChange}
                            getScopeGreaterEqual={this.getScopeGreaterEqual}
                            onClear={this.onClear}
                            onSummary={this.onSummary}
                        />
                        {this.state.view_level === "CallAnalysisSummaryCompare" && 
                            <Menu
                                state={this.state}
                                userInfo={this.props.userInfo}
                                langText={this.props.langText}
                                getFilterData={this.getFilterData}
                                onChange={this.onChange}
                                onClickAccordion={this.onClickAccordion}
                                validationHandle={this.validationHandle}
                                onKeyDownIntCheck={this.onKeyDownIntCheck}
                                onSelectChange={this.onSelectChange}
                                onMultiSelectChange={this.onMultiSelectChange}
                                onTimeChange={this.onTimeChange}
                                getScopeGreaterEqual={this.getScopeGreaterEqual}
                                onClear={this.onClear}
                                onSummary={this.onSummary}
                                compareFlag={true}
                            />}
                    </SearchMenu>
                </Col>
                <div className="search-menu-div">
                    <Button
                        className={`search-menu-button ${(this.state.menu_flag && this.state.view_level !== "CallAnalysisSummaryCompare")
                            ? "open" 
                            : (this.state.menu_flag && this.state.view_level === "CallAnalysisSummaryCompare" )
                                ? "open-compare" 
                                : ""}`}
                        onClick={() => this.menuHandle()}
                    >
                        {
                            this.state.menu_flag
                            ? this.props.langText.Body.Quotation
                            : this.props.langText.Body.LessThan
                        }
                    </Button>
                </div> 
            </BlockUi>
        );
    }
}
