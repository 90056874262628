export default {
    // GW
    GW_gatewayName:                 128,
    GW_gatewayRegisterId:           40,
    GW_gatewayRegisterPw:           128,
    GW_ipaddress:                   40,
    GW_port:                        5,
    GW_memo:                        20,
    // PBX
    PBX_pbx_name:                   128,
    PBX_hostName:                   128,
    PBX_publicIpAddress:            40,
    PBX_privateIpAddress:           40,
    PBX_wssPort:                    9,
    PBX_bindPort:                   9,
    PBX_ftpPort:                    9,
    instanceId:                     128,
    PBX_memo:                       256,
    // 外線
    External_memo:                  30,
    External_description:           256,
    External_display_number:        20,
    External_back_number:           20,
    External_channel_number:        5,
    External_wait_number:           5,
    External_prefix:                3,
    ExtenCount:                     134,
    External_timeout_sec:           3,
    External_timeout_sec_max:       300,
    External_dispensing_time:   2,
    External_dispensing_count:    5,
    External_wait_call_timeout_seconds:    5,

    // 内線
    Extension_memo:                 20,
    Extensions_insertcount:         3,
    Extension_description:          256,
    // 内線グループ
    InboundG_group_name:            256,
    // グループ番号
    Group_extension_memo:           20,
    Group_extension_number_num:     3,
    Group_extension_description:    256,
    // 発信規制
    OutboundR_telno:                19,
    OutboundR_memo:                 20,
    // 着信拒否
    InboundR_telno:                 19,
    InboundR_memo:                  20,
    // 営業時間管理
    BusCale_update_calendarname:    128,
    BusCale_nai_kyodou_box:         20,
    BusCale_gai_kyodou_box:         20,
    // 音声管理
    voice_audioName:                128,
    voice_memo:                     128,
    // 会社管理
    Company_companyName:            128,
    Company_prefix:                 3,
    Company_password:               50,
    Company_memo:                   20,
    Company_omniURL:                256,
    Company_LineType_Channel:       4,
    // 拠点管理
    Department_departmentName:      128,
    Department_memo:                20,
    // フロア
    Floor_prefix:                   2,
    // ユーザー管理
    User_mailAddress:               128,
    User_firstNameCc:               64,
    User_lastNameCc:                64,
    User_firstNameKana:             64,
    User_lastNameKana:              64,
    User_password:                  99,
    User_passwordRe:                99,
    User_oldPassword:               128,
    User_newPassword:               128,
    User_newPasswordRe:             128,
    User_loginPasswordExpireDays:   3,
    User_permissionName:            256,
    // 顧客管理
    Customer_firstNameCc:           64,
    Customer_lastNameCc:            64,
    Customer_firstNameKana:         64,
    Customer_lastNameKana:          64,
    Customer_ComnpanyName:          64,
    Customer_mailAddress:           128,
    Customer_tag_tel:               20,
    // 通話履歴

    // 権限管理
    Permission_permissionName:      256,

    //スクリプト管理
    Script_check_tel:               11,
    Script_name:                    128,
    Script_description:             128,
    //自動発信リスト
    AutoCallList_Name:            256,

    //業務管理
    Task_Name:                     128,
    Task_Memo:                      256,

    // オペレーター管理
    Operator_Id:   120,
    Operator_Name: 128,
    Operator_Password:     99,
    Operator_PasswordRe:   99,

    // 自動発信
    Outbound_Rate: 3,
    AutoCall_Time_Out: 2,
    AutoCall_Connected_Time_Out: 3,
    AutoCall_Retry: 2,
    AutoCall_Report: 3,

    // 通話品質分析
    Template_Name: 128,
    File_Name: 256

    // 独立機能で動作しているもの
    // MultiSelect.js
    // 変数:charalimit_count
    // 該当機能:内線検索の検索ボックス

}
