import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import TotalCallLineChart from "./TotalCallLineChart";
import CallAnalysisCharts from "./CallAnalysisCharts";
import SetDataWithSpeechBubble from "../../Elements/AscElements/SetDataWithSpeechBubble";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";

export default class Compare extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            total_call_view_level: "count",
            call_heat_map_view_level: "all",
            compare_average_list: [
                {
                    key: this.props.langText.Body.TalkingTime, 
                    value: "search_total_billsec"
                },{
                    key: this.props.langText.Body.Score, 
                    value: "score.summary_total_score"
                },{
                    key: this.props.langText.Body.TalkConflictCount, 
                    value: "talk_conflict_count.summary_total_talk_conflict_count"
                },{
                    key: this.props.langText.Body.RallyingCount, 
                    value: "rallying_count.summary_total_rallying_count"
                },{
                    key: this.props.langText.Body.SilenceCount, 
                    value: "silence_count.summary_total_silence_count"
                },{
                    key: this.props.langText.Body.TalkRate, 
                    value: "talk_per.summary_total_talk_per"
                },{
                    key: this.props.langText.Body.TalkSpeedDifference, 
                    value: "speech_speed_diff.summary_total_speech_speed_diff"
                }
            ]
        }
    }
    
    onViewChange = (viewMode) => {
        switch (viewMode) {
            case "count":
            case "time":
                this.setState({
                    total_call_view_level: viewMode
                });
                break;
            case "all":
            case "out":
            case "in":
                this.setState({
                    call_heat_map_view_level: viewMode
                });
                break;
            default:
                break;
        }
    }

    getCompareAverageValueInfo = () => {
        let {
            langText,
            data
        } = this.props;

        let compare_list = this.state.compare_average_list;
        let compare_list_arr = [];
        let summary_method_label = langText.Body.Average;

        compare_list.forEach(row => {
            let font_class_name = "font-color-black";
            if (data.length === 2) {
                let compare_result = "";

                let value_a = 0;
                let value_b = 0;
                if (row.value === "search_total_billsec") {
                    value_a = data[0] && data[0][row.value] ? data[0][row.value] : 0;
                    value_b = data[1] && data[1][row.value] ? data[1][row.value] : 0;
                } else {
                    value_a = data[0] && data[0][row.value.split('.')[0]] && data[0][row.value.split('.')[0]][row.value.split('.')[1]] ? 
                        data[0][row.value.split('.')[0]][row.value.split('.')[1]] : 0;
                    value_b = data[1] && data[1][row.value.split('.')[0]] && data[1][row.value.split('.')[0]][row.value.split('.')[1]] ? 
                        data[1][row.value.split('.')[0]][row.value.split('.')[1]] : 0;
                }

                if (value_a === value_b) {
                    compare_result = "0.0" + langText.Body.Percent + " " + langText.Body.High;
                } else {
                    let compare_rate = (((value_a - value_b) / value_b) * 100).toFixed(1);
                    if (isNaN(compare_rate) || !isFinite(compare_rate) || parseInt(compare_rate) === 0) {
                        compare_result = " - ";
                    } else if (compare_rate > 0 && isFinite(compare_rate)) {
                        font_class_name = "font-color-00B9AB"
                        compare_result = compare_rate + langText.Body.Percent + " " + langText.Body.High;
                    } else if (compare_rate < 0) {
                        font_class_name = "font-color-E01D5A"
                        compare_result = compare_rate + langText.Body.Percent + " " + langText.Body.Low;
                    } else {
                        return;
                    }
                }

                compare_list_arr.push(
                    <li>{
                        this.sprintfTag(
                            langText.Message.CompareHighValueDescription,
                            <span>{`${langText.Body.CompareA} `}</span>,
                            <span className="font-weight-bold">{` ${summary_method_label}${row.key} `}</span>,
                            <span>{`${langText.Body.CompareB} ${langText.Body.Than} `}</span>, 
                            <span className={font_class_name}>{compare_result}</span>
                        )
                    }</li>);
            } else {
                return;
            }
        });

        return (
            <div className="call-compare-average-value">
                <ul>
                    {compare_list_arr}
                </ul>
            </div>
        );
    }

    getTotalCallViewModeInfo = () => {
        let {
            langText
        } = this.props;

        return (
            <ButtonGroup className="call-chart-button">
                <Button
                    key="TotalCallCount"
                    onClick={e => this.onViewChange("count")}
                    className={this.state.total_call_view_level === "count" ? "call-chart-button-focus" : ""}>
                    {langText.Body.TotalCallCount}
                </Button>
                <Button
                    key="TotalCallTime"
                    onClick={e => this.onViewChange("time")}
                    className={this.state.total_call_view_level === "time" ? "call-chart-button-focus" : ""}>
                    {langText.Body.TotalCallTime}
                </Button>
            </ButtonGroup>
        );
    }

    createTooltipContent = (type) => {
        let {
            langText
        } = this.props;
        let text = "";

        switch (type) {
            case "FrequencyDifferenceDescription":
                text = (
                    <>
                        <p>
                            {langText.Message[type][0]}<br />
                            {langText.Message[type][1]}
                        </p>
                        <p>
                            {langText.Message[type][2]}<br />
                            {langText.Message[type][3]}<br />
                            {langText.Message[type][4]}
                        </p>
                    </>
                );
                break;
            case "ScoreDescription": 
                text = (
                    <>
                        <p>
                            {langText.Message[type][0]}
                        </p>
                        <div>{langText.Message[type][1]}</div>
                        <p className="score-tooltip">
                            {langText.Message[type][2]}<br />
                            {langText.Message[type][3]}<br />
                            {langText.Message[type][4]}<br />
                            {langText.Message[type][5]}
                        </p>
                        <table className="score-tooltip-table">
                            <tr>
                                <td>{langText.Message[type][6][0]}</td>
                                <td>{langText.Message[type][6][1]}</td>
                                <td>{langText.Message[type][11][0]}</td>
                                <td>{langText.Message[type][11][1]}</td>
                            </tr>
                            <tr>
                                <td>{langText.Message[type][7][0]}</td>
                                <td>{langText.Message[type][7][1]}</td>
                                <td>{langText.Message[type][12][0]}</td>
                                <td>{langText.Message[type][12][1]}</td>
                            </tr>
                            <tr>
                                <td>{langText.Message[type][8][0]}</td>
                                <td>{langText.Message[type][8][1]}</td>
                                <td>{langText.Message[type][13][0]}</td>
                                <td>{langText.Message[type][13][1]}</td>
                            </tr>
                            <tr>
                                <td>{langText.Message[type][9][0]}</td>
                                <td>{langText.Message[type][9][1]}</td>
                                <td>{langText.Message[type][14][0]}</td>
                                <td>{langText.Message[type][14][1]}</td>
                            </tr>
                            <tr>
                                <td>{langText.Message[type][10][0]}</td>
                                <td>{langText.Message[type][10][1]}</td>
                                <td>{langText.Message[type][15][0]}</td>
                                <td>{langText.Message[type][15][1]}</td>
                            </tr>
                        </table>
                    </>
                );
                break;
            default:
                break;
        }

        return (
            <div>
                {text}
            </div>
        );
    }

    secToTime = (secs) => {
        let time = Math.round(secs),
            hour = Math.floor(time / 3600),
            minutes = Math.floor(time / 60) % 60,
            sec = time % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    render() {
        return (
            <div className="compare">
                <Row>
                    <Col sm={12} md={12}>
                        {this.getCompareAverageValueInfo()}
                    </Col>
                </Row>
                <Row className="margin-top-1">
                    <Col sm={12} md={12}>
                        {this.getTotalCallViewModeInfo()}
                        <TotalCallLineChart
                            state={this.state}
                            data={this.props.data}
                            langText={this.props.langText}
                            hiddenFiltered={this.props.state.hidden_filtered}
                            secToTime={this.secToTime}
                            compareFlag={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12}>
                        <CallAnalysisCharts
                            state={this.state}
                            data={this.props.data}
                            langText={this.props.langText}
                            createTooltipContent={this.createTooltipContent}
                            secToTime={this.secToTime}
                            compareFlag={true}
                        />
                    </Col>
                </Row>
                <Row className="padding-top-15">
                    <Col sm={12} md={12}>
                        <Table bordered className="compare-table">
                            <tbody>
                                <tr>
                                    <th colSpan="3">
                                        <span className="margin-left-05">{this.props.langText.Body.Other}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="margin-left-05">{""}</span>
                                    </td>
                                    <td>
                                        <span className="margin-left-05 font-color-0080FF">{this.props.langText.Body.CompareA}</span>
                                    </td>
                                    <td>
                                        <span className="margin-left-05 font-color-FFB366">{this.props.langText.Body.CompareB}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.TalkSpeed}
                                                speechBubbleData={this.props.langText.Message.TalkSpeedDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 && Array.isArray(this.props.data) && this.props.data[0]
                                            ? Number(this.props.data[0].speech_speed).toLocaleString() : 0}{this.props.langText.Body.TextSecond}</span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 && Array.isArray(this.props.data) && this.props.data[1]
                                            ? Number(this.props.data[1].speech_speed).toLocaleString() : 0}{this.props.langText.Body.TextSecond}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.FillerCount}
                                                speechBubbleData={this.props.langText.Message.FillerDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />     
                                        </span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 && Array.isArray(this.props.data) && this.props.data[0]
                                            ? Number(this.props.data[0].filler_count).toLocaleString() : 0}{` ${this.props.langText.Body.CountPerMinute}`}</span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 && Array.isArray(this.props.data) && this.props.data[1]
                                            ? Number(this.props.data[1].filler_count).toLocaleString() : 0}{` ${this.props.langText.Body.CountPerMinute}`}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.Frequency}
                                                speechBubbleData={this.props.langText.Message.FrequencyDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 &&  Array.isArray(this.props.data) && this.props.data[0]
                                            ? Number(this.props.data[0].frequency).toLocaleString() : 0}{` ${this.props.langText.Body.Hz}`}</span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 &&  Array.isArray(this.props.data) && this.props.data[1]
                                            ? Number(this.props.data[1].frequency).toLocaleString() : 0}{` ${this.props.langText.Body.Hz}`}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.FrequencyDifference}
                                                speechBubbleData={this.createTooltipContent("FrequencyDifferenceDescription")}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 &&  Array.isArray(this.props.data) && this.props.data[0]
                                            ? Number(this.props.data[0].frequency_diff).toLocaleString() : 0}{` ${this.props.langText.Body.Percent}`}</span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 &&  Array.isArray(this.props.data) && this.props.data[1]
                                            ? Number(this.props.data[1].frequency_diff).toLocaleString() : 0}{` ${this.props.langText.Body.Percent}`}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.Accent}
                                                speechBubbleData={this.props.langText.Message.AccentDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 && this.props.data[0] ? Number(this.props.data[0].accent).toLocaleString() : 0}</span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 && this.props.data[1] ? Number(this.props.data[1].accent).toLocaleString() : 0}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.AccentDifference}
                                                speechBubbleData={this.props.langText.Message.AccentDifferenceDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 &&  Array.isArray(this.props.data) && this.props.data[0]
                                            ? Number(this.props.data[0].accent_diff).toLocaleString() : 0}{` ${this.props.langText.Body.Percent}`}</span>
                                    </td>
                                    <td>
                                        <span>{this.props.data.length > 0 &&  Array.isArray(this.props.data) && this.props.data[1]
                                            ? Number(this.props.data[1].accent_diff).toLocaleString() : 0}{` ${this.props.langText.Body.Percent}`}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}
