import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import SetDataWithSpeechBubble from "../../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";

export default class CommonLineChart extends AscComponent {
    constructor(props) {
        super(props);
    
        this.state = {
            series: [{
                data: []
            }],
            options: {
                chart: {
                    zoom: {
                        enabled: false,
                        offsetX: 10,
                        offsetY: 10,
                        style: {
                            color: '#333',
                            fontSize: '16px',
                        }, 
                    },
                    toolbar: {
                        show: false
                    },
                    background: '#fff'
                },
                colors: ['#0080FF'],
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                stroke: {
                    curve: 'straight',
                    width: 1
                },
                xaxis: {
                    categories: [],
                    tickAmount: 4,
                    axisBorder: {
                        show: false 
                    },
                    axisTicks: {
                        show: false 
                    },
                    labels: {
                        rotate: 0
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    tickAmount: 4,
                    labels: {
                        formatter: function (value) {
                            return value;
                        }
                    }
                }
            }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.data !== this.props.data    
        ) {
            if (this.props.compareFlag) {
                this.onChartDataCompareChange();
            } else {
                this.onChartDataChange();
            }
        }
    }

    onChartDataChange = () => {
        let {
            data,
            title,
            langText,
            unit,
            secToTime
        } = this.props;
        let category = [];
        let series_data = [];
        let series_name = title;
        let show_flag = false;
        let chart_unit = unit;

        data.forEach(row => {
            let value = 0;
            if (row.value && row.value.value) value = row.value.value.toFixed(2);
            else if (row.value && row.value.values && row.value.values["50.0"]) value = row.value.values["50.0"].toFixed(2);

            category.push(row.key_as_string);
            series_data.push(value);
        });

        if (title === langText.Body.Score) {
            show_flag = true;
            chart_unit = "";
        }

        this.setState({
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: category,
                    labels: {
                        ...this.state.options.xaxis.labels,
                        formatter: function(val) {
                            const index = category.indexOf(val);
                            if (show_flag) {
                                return val;
                            } else {
                                if (index === 0 || index === category.length - 1) return val;
                                else return '';
                            }
                        }
                    },
                },
                yaxis: {
                    ...this.state.options.yaxis,
                    labels: {
                        ...this.state.options.yaxis.labels,
                        show: show_flag
                    }
                },
                grid: {
                    show: show_flag
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        let x_label = category;
                        return `
                            <div class="common-line-chart-tooltip">
                                <div class="common-line-chart-tooltip-date">${x_label[dataPointIndex]}</div>
                                <div class="common-line-chart-tooltip-value">
                                    <span>${title === langText.Body.TalkingTime ? secToTime(series[seriesIndex][dataPointIndex]) : series[seriesIndex][dataPointIndex].toLocaleString()}</span>
                                    <span> ${chart_unit}</span>
                                </div>
                            </div>
                        `;
                    }
                }
            },
            series:[{
                ...this.state.series,
                name: series_name,
                data: series_data
            }],
        });
    }

    onChartDataCompareChange = () => {
        let {
            data,
            title,
            langText,
            unit,
            secToTime,
            dataLabel
        } = this.props;
        let category = [];
        let series = [];
        let series_name = title;
        let show_flag = false;
        let chart_unit = unit;

        const a_date_arr = data && data[0] && data[0][dataLabel] ? 
            data[0][dataLabel]["data_by_date"].map(row => {return row.key_as_string}) : [];
        const b_date_arr = data && data[1] && data[1][dataLabel] ? 
            data[1][dataLabel]["data_by_date"].map(row => {return row.key_as_string}) : [];
        const total_length = Math.max(a_date_arr.length, b_date_arr.length);

        const orderedByDate = category.sort((a, b) => new Date(a) - new Date(b));
        const set = new Set(orderedByDate);
        const setedCategory = [...set];

        data.forEach((row, index) => {
            let series_temp = {};
            let series_data = [];

            for (let i = 0; i < total_length; i++) {
                let value = 0;
                let index_data = row[dataLabel]["data_by_date"][i] || null;
                if (index_data && index_data.value && index_data.value.value) 
                    value = index_data.value.value.toFixed(2);
                else if (index_data && index_data.value && index_data.value.values && index_data.value.values["50.0"]) 
                    value = index_data.value.values["50.0"].toFixed(2);

                if (i < row[dataLabel]["data_by_date"].length) series_data.push(value); 
                else series_data.push(null);
            }

            series_temp.name = index === 0 ? `${this.props.langText.Body.CompareA} ${series_name}` 
                : `${this.props.langText.Body.CompareB} ${series_name}`;
            series_temp.data = series_data;
            series.push(series_temp);
        });

        if (title === langText.Body.Score) {
            show_flag = true;
            chart_unit = "";
        }

        this.setState({
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: a_date_arr,
                    labels: {
                        ...this.state.options.xaxis.labels,
                        formatter: function(val) {
                            const index = a_date_arr.indexOf(val);
                            if (show_flag) {
                                return val;
                            } else {
                                if (index === 0 || index === a_date_arr.length - 1) return val;
                                else return '';
                            }
                        }
                    },
                },
                yaxis: {
                    ...this.state.options.yaxis,
                    labels: {
                        ...this.state.options.yaxis.labels,
                        show: show_flag
                    }
                },
                grid: {
                    show: show_flag
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        let data_a = series[0][dataPointIndex] || 0;
                        let data_b = series[1][dataPointIndex] || 0;

                        return `
                            <div class="common-line-chart-tooltip">
                                <div class="common-line-chart-tooltip-date">
                                    ${series[0][dataPointIndex] != null ? a_date_arr[dataPointIndex] : ""}
                                </div>
                                <div class="common-line-chart-tooltip-padding">
                                    <span>${series[0][dataPointIndex] != null ? langText.Body.CompareA + " : " : ""}</span>
                                    <span class="font-color-0080FF">
                                        ${series[0][dataPointIndex] != null ? title === langText.Body.TalkingTime 
                                        ? secToTime(data_a) : data_a.toLocaleString() : ""}</span>
                                    <span class="font-color-0080FF">${series[0][dataPointIndex] != null ? " " + chart_unit : ""}</span>
                                </div>
                                <div class="common-line-chart-tooltip-date">
                                    ${series[1][dataPointIndex] != null ? b_date_arr[dataPointIndex] : ""}
                                </div>
                                <div class="common-line-chart-tooltip-padding">
                                    <span>${series[1][dataPointIndex] != null ? langText.Body.CompareB + " : " : ""}</span>
                                    <span class="font-color-FFB366">
                                        ${series[1][dataPointIndex] != null ? title === langText.Body.TalkingTime 
                                        ? secToTime(data_b) : data_b.toLocaleString() : ""}</span>
                                    <span class="font-color-FFB366">${series[1][dataPointIndex] != null ? " " + chart_unit : ""}</span>
                                </div>
                            </div>
                        `;
                    }
                },
                colors: ['#0080FF', '#FFB366']
            },
            series,
        });
    }


    render() {
        let level = this.props.title === this.props.langText.Body.Score ? "score" : "";
        let chart_unit = this.props.unit;
        let data_label_a = this.props.compareFlag && this.props.data[0] && this.props.data[0][this.props.dataLabel]
            ? this.props.data[0][this.props.dataLabel][`summary_total_${this.props.dataLabel}`] : 0;
        let data_label_b = this.props.compareFlag && this.props.data[1] && this.props.data[1][this.props.dataLabel]
            ? this.props.data[1][this.props.dataLabel][`summary_total_${this.props.dataLabel}`] : 0;

        return (
            <div className="common-line-chart">
                <div className="padding-left-1em">
                    <div className={`common-line-chart-title ${level}`}>
                        <SetDataWithSpeechBubble
                            displayData = {this.props.title}
                            speechBubbleData = {this.props.description}
                            infoIconDisplayFlag = {true}
                            infoIconType = {faIcon.faInfoCircle}
                            infoIconClassName = "info-icon"
                            infoIconColor = "silver"
                            infoIconSize = "lg"
                            custom="custom"/>
                    </div>
                    {this.props.compareFlag ? 
                        <Col className="flex-direction-row">
                            <Col className="flex-direction-column margin-right-1">
                                <span className="font-weight-bold fa-12 font-color-0080FF">
                                    {this.props.dataLabel === "call_time" ? this.props.secToTime(data_label_a) : data_label_a}{` ${chart_unit}`}</span>
                                <span className="fa-09">{this.props.langText.Body.CompareA}</span>
                            </Col>
                            <Col className="flex-direction-column">
                                <span className="font-weight-bold fa-12 font-color-FFB366">
                                    {this.props.dataLabel === "call_time" ? this.props.secToTime(data_label_b) : data_label_b}{` ${chart_unit}`}</span>
                                <span className="fa-09">{this.props.langText.Body.CompareB}</span> 
                            </Col>
                        </Col> :
                        <div className="common-line-chart-data-label">
                            <span className="x-large-font-size">{this.props.dataLabel}</span>
                            <span className="medium-font-size">{` ${chart_unit}`}</span>
                        </div>}
                </div>
                <Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height}/>
            </div>
        );
    }
}
