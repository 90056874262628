import React from "react";
import { Row, Col } from "react-bootstrap";
import Component from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import ButtonSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import {
    ViewCallHistoryElement,
    ViewPlayVoiceElement,
    ViewAudioControlElement,
    ViewVoiceTextElement,
    ViewScoreElement,
    ViewTalkListenElement,
    ViewOthersElement,
    ViewFrequencyElement,
    ViewAccentElement,
    ViewTalkSpeedElement,
} from "../Elements/AscElements/CallHistoryAnalysisElements";
import moment from "moment-timezone";
import ASToast from "../Elements/Toast/Toast";

export default class CallHistoryAnalysis extends Component {
    constructor(props) {
        super(props);
        const HZ = this.props.langText.Body.Hz;
        const TEXTSECOND = this.props.langText.Body.TextSecond;
        const OP_LABEL = this.props.langText.Body.Op;
        const CUSTOMER_LABEL = this.props.langText.Body.Customer;
        const OP_SPEED_MSG = this.props.location.state.ct86_call_result_eval_data.operator_speed_message;
        const CUSTOMER_SPEED_MSG = this.props.location.state.ct86_call_result_eval_data.customer_speed_message;
        const SCORE_ALL = this.props.langText.Body.ScoreAll;

        this.state = {
            // table item
            data: this.props.location.state.ct60_call_history_datas,
            selected: this.props.location.state.ct87_voice_log_to_text_data,
            ct86_data: this.props.location.state.ct86_call_result_eval_data,
            comment_alert_flag: false,
            comment_alert_message: "",
            insert_comment: "",
            comment_edit_flag: [],
            voice_alert_flag: false,
            voice_alert_message: "",
            voice_edit_flag: [],
            message_box_data: [],
            message_box_index: 0,
            auto_scroll_flag: false,
            searching_index: 0,
            element_index: 0,
            show: true,
            comment_null_flag: false,
            voice_text_null_flag: false,
            loading: false,
            play_voice_series: [],
            play_voice_options: {
                chart: {
                    type: 'area',
                    height: 350,
                    background: '#fff',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth',
                    width: 1
                },
                xaxis: {
                    tickAmount: this.props.location.state.ct86_call_result_eval_data.operator_frequency_second.length < 10?
                                this.props.location.state.ct86_call_result_eval_data.operator_frequency_second.length -1
                                :10,
                    labels: {
                        formatter: function (val, opt) {
                            let secs = val;
                            let hour = Math.floor(secs / 3600);
                            let minutes = Math.floor(secs / 60) % 60;
                            let sec = Math.round(secs % 60);
                            return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    tickAmount: 6,
                    floating: false,
                    labels: {
                        formatter: (val) => {
                            return Math.abs(val);
                        }
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false
                    }
                },
                fill: {
                    opacity: 0.5
                },
                tooltip: {
                    x: {
                        formatter: function (val, opt) {
                            let secs = opt.dataPointIndex;
                            let hour = Math.floor(secs / 3600);
                            let minutes = Math.floor(secs / 60) % 60;;
                            let sec = secs % 60;

                            return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
                        }
                    },
                    y: {
                        formatter: function (val) {
                            return Math.abs(val)
                        }
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight'
                    },
                    labels: {
                        formatter: function (val) {
                            return Math.abs(val)
                        }
                    }
                },
                grid: {
                    yaxis: {
                        lines: {
                            offsetX: -30
                        }
                    },
                    padding: {
                        left: 20
                    }
                }
            },

            score_series: [parseFloat(this.props.location.state.ct86_call_result_eval_data.score.toFixed(1))],
            score_options: {
                chart: {
                    type: 'radialBar',
                    background: '#fff',
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    padding: {
                        top: -25,
                        bottom: -25
                    }
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: -10,
                                show: true,
                                color: '#888',
                                fontSize: '14px'
                            },
                            value: {
                                formatter: function(val) {
                                return parseInt(val) + SCORE_ALL;
                                },
                                color: '#111',
                                fontSize: '20px',
                                show: true,
                            }
                        }
                    }
                },
                labels: ['スコア'],
            },
            talk_listen_series: [parseFloat(this.props.location.state.ct86_call_result_eval_data.talk_per.toFixed(1)), parseFloat((100 - parseFloat(this.props.location.state.ct86_call_result_eval_data.talk_per.toFixed(1))).toFixed(1))],
            talk_listen_options: {
                chart: {
                    type: 'pie',
                    background: '#fff'
                },
                labels: [this.props.langText.Body.Talk, this.props.langText.Body.Listen],
                legend: {
                    show: false
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff'],
                        fontSize: '14px'
                    },
                    formatter: function (val, opt) {
                        return [val, opt.w.globals.labels[opt.seriesIndex]]
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
            },

            fundamental_frequency_series: [{
                data: [this.props.location.state.ct86_call_result_eval_data.operator_frequency_average.toFixed(1), this.props.location.state.ct86_call_result_eval_data.customer_frequency_average.toFixed(1)]
            }],
            fundamental_frequency_options: {
                chart: {
                    type: 'bar',
                    height: 380,
                    background: '#fff',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff'],
                        fontSize: '20px'
                    },
                    formatter: function (val, opt) {
                        return val + HZ
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: [OP_LABEL, CUSTOMER_LABEL],
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            }
                        }
                    }
                }
            },

            accent_series: [{
                data: [this.props.location.state.ct86_call_result_eval_data.operator_accent.toFixed(1), this.props.location.state.ct86_call_result_eval_data.customer_accent.toFixed(1)]
            }],
            accent_options: {
                chart: {
                    type: 'bar',
                    height: 380,
                    background: '#fff',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff'],
                        fontSize: '20px'
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: [OP_LABEL, CUSTOMER_LABEL]
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            }
                        }
                    }
                }
            },

            talk_speed_series: [{
                data: [
                    this.props.location.state.ct86_call_result_eval_data.operator_talk_speed,
                    this.props.location.state.ct86_call_result_eval_data.customer_talk_speed
                ]
            }],
            talk_speed_options: {
                chart: {
                    type: 'bar',
                    height: 380,
                    background: '#fff',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff'],
                        fontSize: '13px'
                    },
                    formatter: (val, opt) => {
                        if (opt.w.globals.labels[opt.dataPointIndex] === OP_LABEL) {
                            return [val + TEXTSECOND, OP_SPEED_MSG]
                        } else if (opt.w.globals.labels[opt.dataPointIndex] === CUSTOMER_LABEL) {
                            return [val + TEXTSECOND, CUSTOMER_SPEED_MSG]
                        } else {
                            return [val + TEXTSECOND]
                        }
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: [OP_LABEL, CUSTOMER_LABEL]
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            }
                        }
                    }
                }
            },
        }
    }

    componentDidMount() {
        this.setFrequencyData();
    }

    setFrequencyData = () => {
        let operator_frequency_second = this.state.ct86_data.operator_frequency_second;
        let customer_frequency_second = this.state.ct86_data.customer_frequency_second;

        operator_frequency_second = operator_frequency_second.map((row, index) => {
            row = { x: index, y: row.toFixed(1) };
            return row;
        })
        customer_frequency_second = customer_frequency_second.map((row, index) => {
            row = { x: index, y: (-1) * row.toFixed(1) };
            return row;
        });
        
        let play_voice_series = [{
            name: this.props.langText.Body.OperatorVoice,
            data: operator_frequency_second
        },{
            name: this.props.langText.Body.CustomerVoice,
            data: customer_frequency_second
        }]

        this.setState({ play_voice_series });
    }

    onPlayHandle = (audio) => {
        try {
            if (audio.played["length"] === 0 && audio.currentTime === 0) {
                this.saveAccessLogs({
                    "resource": `${this.reactContainerPath}/voiceLogPlay`,
                    "body": {
                        "voice_log_url": audio.currentSrc
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    /**
 * 音声テキスト更新
 * @param {Integer} index
 * @param {String} value
 * @return {Object}
 */
    updateVoiceData = async (index, value) => {
        try {
            if (!value.trim()) {
                //入力内容がない場合、アラートが出る
                return alert(this.props.langText.Message.VoiceTextBlankError);
            }
            let { selected } = this.state;
            let voice_data = [...this.state["selected"]["text_data_json"]];
            voice_data[index] = {
                ...voice_data[index],
                transcript: value || "",
                modified: moment().tz("Asia/Tokyo").format(),
                update_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                update_user_email: this.props.userInfo.user_id,
            }
            let result = await this.ascAxios("post", `CallAnalysis/updateVoiceText`, { id: selected.id, voice_data });
            selected.text_data_json = voice_data;

            this.setState({
                selected,
                voice_alert_flag: true,
                voice_alert_message: this.props.langText.Message.VoiceTextUpdated,
            });
            return result;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataUpdateError");
        }
    }

    render() {
        let setDisplayItem = this.props.location.state && this.props.location.state.bookmark
                            ? { link: "/Bookmark", name: this.props.langText.Body.Bookmark }
                            : { link: "/CallHistory", name: this.props.langText.Body.CallHistoryName };
        return (
            <React.Fragment>
                <ASToast />
                {/* パンくずリスト */}
                <SetBreadCrumb
                    displayItems={[
                        setDisplayItem,
                        { name: this.props.langText.Body.Id + ": " + this.state.data.id },
                    ]}
                />
                <Row>
                    {/* 通話情報 */}
                    <Col xs={12} md={12}>
                    <div className="history-analysis-label top-label">{this.props.langText.Body.CallInfo}</div>
                    </Col>
                    <Col xs={12} md={12}>
                        <ViewCallHistoryElement
                            state={this.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}
                            ct03BookmarkFlag={this.props.location.state.ct03_bookmark_flag}
                            userInfo={this.props.userInfo}
                        />
                    </Col>
                </Row>
                <Row>
                    {/* 音声再生 */}
                    <Col xs={8} md={8}>
                        <div className="history-analysis-label top-label">{this.props.langText.Body.PlayVoice}</div>
                    </Col>
                    {/* 音声テキスト */}
                    <Col xs={4} md={4}>
                        <span className="history-analysis-label top-label">{this.props.langText.Body.VoiceText}</span>
                        {this.state && this.state.selected && this.state.selected.text_data_json.length !== 0 &&
                            //音声テキストがあれば全体音声テキストコピーボタン・自動スクロールボタン表示
                            <>
                                <span className="pannel-btn-right">
                                    <ButtonSpeechBubble
                                        className="control-button"
                                        bsSize="xsmall"
                                        onClick={e => {
                                            let player = document.getElementById("audio-text-player");
                                            let panel = document.getElementById("voice-text-panel");
                                            this.propSetState({
                                                auto_scroll_flag: !this.state.auto_scroll_flag,
                                                message_box_data: [],
                                                message_box_index: 0,
                                            });
                                            player.currentTime = 0;
                                            panel.scrollTo(0, 0)
                                        }}
                                        DisplayIcon="resize-vertical"
                                        speechBubble={this.props.langText.Body.AutoScroll}
                                    />
                                    <ButtonSpeechBubble
                                        className="control-button"
                                        bsSize="xsmall"
                                        onClick={e => this.copyData("voice-all", this.state.selected.text_data_json)}
                                        disabled={this.state.voice_edit_flag.some(row => row)}
                                        DisplayIcon="copy"
                                        speechBubble={this.props.langText.Body.Copy}
                                    />
                                </span>
                            </>
                        }
                    </Col>
                    {/* 音声再生 */}
                    <Col xs={8} md={8}>
                        <ViewAudioControlElement
                            state={this.state}
                            langText={this.props.langText}
                            userInfo={this.props.userInfo}
                            propSetState={this.propSetState}
                            onPlayHandle={this.onPlayHandle}
                            currentPermission={this.props.currentPermission}
                        />
                        <ViewPlayVoiceElement
                            state={this.state}
                            langText={this.props.langText}
                        />
                    </Col>
                    {/* 音声テキスト */}
                    <Col xs={4} md={4}>
                        <ViewVoiceTextElement
                            state={this.state}
                            langText={this.props.langText}
                            userInfo={this.props.userInfo}
                            copyData={this.copyData}
                            propSetState={this.propSetState}
                            updateVoiceData={this.updateVoiceData}
                            currentPermission={this.props.currentPermission}
                        />
                    </Col>
                </Row>
                <Row>
                    {/* 応対評価 */}
                    <Col xs={12} md={12}>
                        <div className="history-analysis-label top-label">{this.props.langText.Body.ResponseReview}</div>
                    </Col>
                    {/* スコア */}
                    <Col xs={4} md={4}>
                        <div className="history-analysis-label">{this.props.langText.Body.Score}</div>
                    </Col>
                    {/* Talk:Listen比率 */}
                    <Col xs={4} md={4}>
                        <div className="history-analysis-label">{this.props.langText.Body.TalkListen}</div>
                    </Col>
                    {/* その他 */}
                    <Col xs={4} md={4}>
                        <div className="history-analysis-label">{this.props.langText.Body.Other}</div>
                    </Col>
                    {/* スコア */}
                    <Col xs={4} md={4}>
                        <ViewScoreElement
                            state={this.state}
                            langText={this.props.langText}
                        />
                    </Col>
                    {/* Talk:Listen比率 */}
                    <Col xs={4} md={4}>
                        <ViewTalkListenElement
                            state={this.state}
                            langText={this.props.langText}
                        />
                    </Col>
                    {/* その他 */}
                    <Col xs={4} md={4}>
                        <ViewOthersElement
                            state={this.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}
                        />
                    </Col>
                </Row>
                <Row>
                    {/* 音声評価 */}
                    <Col xs={12} md={12}>
                        <div className="history-analysis-label top-label">{this.props.langText.Body.VoiceReview}</div>
                    </Col>
                    {/* 基本周波数 */}
                    <Col xs={4} md={4}>
                        <div className="history-analysis-label">{this.props.langText.Body.Frequency}</div>
                    </Col>
                    {/* 抑揚の強弱 */}
                    <Col xs={4} md={4}>
                        <div className="history-analysis-label">{this.props.langText.Body.AccentStrength}</div>
                    </Col>
                    {/* 話速 */}
                    <Col xs={4} md={4}>
                        <div className="history-analysis-label">{this.props.langText.Body.TalkSpeed}</div>
                    </Col>
                    {/* 基本周波数 */}
                    <Col xs={4} md={4}>
                        <ViewFrequencyElement
                            state={this.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}
                        />
                    </Col>
                    {/* 抑揚の強弱 */}
                    <Col xs={4} md={4}>
                        <ViewAccentElement
                            state={this.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}
                        />
                    </Col>
                    {/* 話速 */}
                    <Col xs={4} md={4}>
                        <ViewTalkSpeedElement
                            state={this.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}