import React from "react";
import AscComponent from "../../../components/AscComponent";
import CommonTable from "../Table/CommonTable";
import moment from "moment";
import { Row, Button, Glyphicon, FormControl, Alert, ButtonGroup } from "react-bootstrap";
import ButtonSpeechBubble from "../../Elements/AscElements/SetButtonWithSpeechBubble";
import Highlighter from "react-highlight-words";

/**
 * 通話詳細表示
 */
export class ViewCallHistoryElement extends AscComponent {
    
    render() {
        let columns = [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                width: this.props.boardWidth.sId,
            },
            {
                Header: this.props.langText.Body.ExtensionNumber,
                accessor: "ct60_call_histories.extension_number",
                width: this.props.boardWidth.small,
                Cell: data => {
                    let returnValue = data.value;
                    if(
                        this.props.state.selected
                        && this.props.state.selected.ct60_call_histories
                        && this.props.state.selected.ct60_call_histories.cm51_extension_numbers
                        && this.props.state.selected.ct60_call_histories.cm51_extension_numbers.memo
                    ){
                        returnValue = data.value + "(" + this.props.state.selected.ct60_call_histories.cm51_extension_numbers.memo + ")"
                    }
                    return returnValue
                }
            },
            {
                Header: this.props.langText.Body.InOutType,
                accessor: "ct60_call_histories.inout_type",
                width: this.props.boardWidth.xsmall,
            },
            {
                Header: this.props.langText.Body.ExternalNumber,
                accessor: "ct60_call_histories.display_number",
                Cell: data => {
                    let returnValue = data.value;
                    if(
                        this.props.state.selected
                        && this.props.state.selected.ct60_call_histories
                        && this.props.state.selected.ct60_call_histories.cm61_external_numbers
                        && this.props.state.selected.ct60_call_histories.cm61_external_numbers.memo
                    ){
                        returnValue = data.value + "(" + this.props.state.selected.ct60_call_histories.cm61_external_numbers.memo + ")"
                    }
                    return returnValue
                }
            },
            {
                Header: this.props.langText.Body.DestUserNumber,
                accessor: "ct60_call_histories.dest_tel_no",
                width: this.props.boardWidth.smedium,
            },
            {
                Header: this.props.langText.Body.StartTime,
                accessor: "ct60_call_histories.start_datetime",
                width: this.props.boardWidth.medium,
                Cell: data => {
                    return data.value ? this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"}) : "";
                }
            },
            {
                Header: this.props.langText.Body.TalkingTime,
                accessor: "ct60_call_histories.billsec",
                width: this.props.boardWidth.small,
                Cell: data => {
                    return this.secToTime(data.value || 0);
                }
            }
        ];

        let data = [this.props.state.selected];

        return (
            <CommonTable
                className="call-analysis-modal-table"
                label = {this.props.langText.Body.CallHistoryName}
                showPagination = {false}
                columns = {columns}
                data = {data}
                showPaginationBottom = {false}
                sortable = {false}
                style={{height:"100px"}}
                pageSize = {1}
                loadingText={this.props.langText.Table.LoadingText}
                noDataText={this.props.langText.Table.NoDataText}
                rowsText={this.props.langText.Table.RowsText}
            />
        );
    }
}

/**
 * 音声テキスト表示
 */
export class ViewVoiceTextElement extends AscComponent {
    //音声テキスト編集欄が変更されるとstate更新
    editVoiceData = (index, value) => {
        let voice_null_flag = !value.trim();
        let text_data_json = [...this.props.state.selected.text_data_json];
        text_data_json[index].transcript_edit = value;
        this.props.propSetState({text_data_json, voice_null_flag});
    }

    //音声テキスト編集ボタン押下時、音声テキスト編集欄表示か音声テキスト更新
    onClickEditBtn = (index) => {
        let voice_edit_flag = [...this.props.state.voice_edit_flag];
        let text_data_json = [...this.props.state.selected.text_data_json];
        if(voice_edit_flag[index]){
            //voice_edit_flag[index]がtrueの場合、flagをfalseに変更してサーバー側に編集した音声テキストを送ってDB更新
            voice_edit_flag[index] = false;
            this.props.updateVoiceData(index, this.props.state.selected.text_data_json[index].transcript_edit);
        } else {
            //voice_edit_flag[index]がfalseの場合、編集中の他の音声テキストの編集flagをfalseに、
            //選択した音声テキストの編集flagをtrueに変更して音声テキストを編集できるようにする
            voice_edit_flag = voice_edit_flag.map( row => {
                row = false;
            })
            voice_edit_flag[index] = true;
            text_data_json[index].transcript_edit = text_data_json[index].transcript;
        }
        this.props.propSetState({voice_edit_flag, text_data_json, voice_null_flag: false});
    }
    
    //アラート閉じる
    closeAlert = () => {
        this.props.propSetState({voice_alert_flag: false});
    }

    //音声テキスト一覧表示
    viewVoiceMessage = () => {
        let returnItems = [];
        let user_check_flag = false;
        if(this.props.state.selected && this.props.state.selected.text_data_json.length){
            this.props.state.selected.text_data_json.forEach((row, index) => {
                user_check_flag = row.inout === "out"? true: false;
                returnItems.push(
                    <React.Fragment key = {index}>
                    <div id={"message-box-"+index} className={"message-div"+" "+(user_check_flag? "right":"left")}>
                        <div className = {"message-box"+" "+(user_check_flag? "right":"left")}>
                            <Row>
                                <span className={"voice-time"+" "+(user_check_flag? "right":"left")} onClick = {e=> this.messageClickHandle(index)}>
                                    {this.secToTime(row.startTime || 0)}
                                </span>
                                {row.modified &&
                                    <span className="data-updated">
                                        {this.props.langText.Body.Updated}
                                    </span>
                                } 
                                <span className="analysis-btn-group" >
                                    <ButtonSpeechBubble
                                        key = "copy-btn"
                                        bsSize="xsmall"
                                        onClick={e=>this.props.copyData("voice-each",row)}
                                        className="control-button"
                                        disabled = {this.props.state.voice_edit_flag && this.props.state.voice_edit_flag[index]}
                                        DisplayIcon="copy"
                                        speechBubble={this.props.langText.Body.Copy}
                                    />
                                    {this.props.currentPermission.edit &&
                                        <ButtonSpeechBubble
                                            key = "edit-btn"
                                            bsSize="xsmall"
                                            onClick={e => this.onClickEditBtn(index)}
                                            className="control-button"
                                            disabled = {this.props.state.voice_edit_flag[index] && this.props.state.voice_null_flag}
                                            DisplayIcon="pencil"
                                            speechBubble={this.props.langText.Body.Edit}
                                        />
                                    }
                                </span>
                            </Row>
                            {this.props.state.voice_edit_flag && this.props.state.voice_edit_flag[index]
                            ?
                            //編集ボタン押下時、音声テキスト編集欄表示・編集可能
                            <FormControl
                                componentClass="textarea"
                                className="voice-data-edit"
                                rows="2"
                                cols={row.transcript? row.transcript.length: 1}
                                defaultValue={row.transcript}
                                onChange={e => this.editVoiceData(index, e.target.value)}
                            />
                            :
                            // 検索したキーワードはハイライトされて表示
                            <div className="voice-data" onClick = {e=> this.messageClickHandle(index)}>
                                {this.props.getKeywordData("keyword")
                                    ? <Highlighter
                                        highlightClassName="searched-keyword"
                                        searchWords={this.props.getKeywordData("keyword")}
                                        autoEscape={true}
                                        textToHighlight={row.transcript}
                                    />
                                    : row.transcript
                                }
                            </div>
                            }
                        </div>
                    </div>
                    </React.Fragment>
                );
                //最後にマージン用div入れる
                if(index === this.props.state.selected.text_data_json.length -1){
                    returnItems.push(
                        <div className="voice-last-index" key="last-index">
                            &nbsp;
                        </div>
                    )
                }
            });
        } else {
            //音声テキストが存在しない場合、メッセージ表示
            returnItems.push(
                <div key="no-voice-message" className="no-voice-message">
                    {this.props.langText.Message.NoVoiceMessage}
                </div>
            )
        }
        return returnItems;
    }

    //音声テキストボクスクリックしたら、音声テキストの時点から音声再生
    messageClickHandle = (index) =>{
        let player = document.getElementById("audio-text-player");
        let {
            selected
        } = this.props.state;
        this.props.propSetState({
            message_box_data: selected.text_data_json[index],
            message_box_index: index,
            message_click_flag: true
        });

        player.currentTime = selected.text_data_json[index].startTime;
        initMessageColor();
    }


    render(){
        return(
            <div id = "voice-text-panel" className = {"voice-text-panel "+ (this.props.state.auto_scroll_flag&&"pannel-hidden")}>
                {
                    //ボイステキスト入力・更新・コピーの場合アラートメッセージ表示
                    this.props.state.voice_alert_flag &&
                    <Alert bsStyle="info" className="voice-alert" onDismiss = {this.closeAlert} >
                        {this.props.state.voice_alert_message}
                    </Alert>
                }
                {this.viewVoiceMessage()}
            </div>
        )
    }
}

export class ViewAudioControlElement extends AscComponent {
    constructor(props) {
        super(props);
        const {
            selected
        } = this.props.state;

        if (selected && selected.text_data_json && selected.text_data_json.length > 0) {
            const lastIndex = selected.text_data_json.length - 1;
            this.props.propSetState({
                lastMessageEndTime: selected.text_data_json[lastIndex].endTime
            })
        }
    }
    //音声プレイヤーの再生バー変更時、表示中のテキスト変更等操作
    onSeekChange = (e) =>{
        let {
            selected,
            message_click_flag
        } = this.props.state;
        let player = document.getElementById("audio-text-player");
        let panel = document.getElementById("voice-text-panel");
        let element, element2;
        this.props.propSetState({message_click_flag: false});
        if(!message_click_flag){
            //音声テキストメッセージフラグがfalseだったら、音声プレイヤーの現在再生時間に表示音声テキストメッセージを合わせる
            for (let i = 0; i < selected.text_data_json.length; i++) {
                let currentTime = Math.round(player.currentTime);
                let startTime = Math.round(selected.text_data_json[i].startTime);
                let endTime = selected.text_data_json[i+1]? Math.round(selected.text_data_json[i+1].startTime): Math.round(selected.text_data_json[i].endTime);
                if(currentTime >= startTime && currentTime <= endTime){
                    element = document.getElementById("message-box-"+i);
                    element2 = document.getElementById("message-box-"+(i+1));
                    let rect = element.getBoundingClientRect();
                    let rect2 = element2? element2.getBoundingClientRect(): element.getBoundingClientRect();
                    panel.scrollTo(0, (rect.bottom + rect2.bottom)/2 + panel.scrollTop -440);
                    this.props.propSetState({
                        message_box_data: selected.text_data_json[i],
                        message_box_index: i,
                    });
                    break;
                }
            }
        }
    }

    //時間アップデート時、表示中のテキスト操作
    onTimeUpdateHandle = (e) =>{
        let player = document.getElementById("audio-text-player");
        let {
                message_box_data,
                message_box_index,
                selected,
                lastMessageEndTime
            } = this.props.state;
        const ELEMENT_CLASS_NAMES = ["current-message-box-right", "current-message-box-left"];
        if (
            message_box_data === undefined ||
            message_box_data.length === 0 ||
            Math.floor(player.currentTime) === 0
        ) {
            //message_box_dataが無い場合、最初の再生情報を入れる
            this.props.propSetState({
                message_box_data: selected.text_data_json[0],
                message_box_index: 0,
                message_click_flag: false
            });

            const currentElement = document.getElementById("message-box-0");
            if (currentElement) {
                const elementClassName =
                    selected.text_data_json[0].inout === "out"
                        ? ELEMENT_CLASS_NAMES[0]
                        : ELEMENT_CLASS_NAMES[1];
                currentElement.firstElementChild.classList.add(elementClassName);
            }
        } else if(
            message_box_data
            && (message_box_data.startTime || message_box_data.startTime === 0)
            && (Math.floor(player.currentTime) >= Math.ceil(message_box_data.endTime))
        ) {
            //音声テキストデータ有り・startTime有り・音声テキストデータと音声プレイヤーの現在時間一致して、
            //自動スクロールフラグがtrueだったらパンネルスクロールさせる
            if (this.props.state.auto_scroll_flag) {
                document.getElementById("voice-text-panel").scrollBy(0, 85.5);
            }

            this.props.propSetState({
                message_box_data: selected.text_data_json[message_box_index + 1],
                message_box_index: message_box_index + 1,
                message_click_flag: false
            });

            const current_message_box_index = message_box_index + 1;
            if (Math.floor(player.currentTime) > lastMessageEndTime) return false;

            const prevElementIndex = current_message_box_index > 0 ? current_message_box_index - 1 : 0
            const prevElement = document.getElementById("message-box-" + prevElementIndex);
            if (prevElement)
                prevElement.firstElementChild.classList.remove(...ELEMENT_CLASS_NAMES);

            const currentElement = document.getElementById("message-box-" + current_message_box_index);
            if (currentElement) {
                const elementClassName =
                    selected.text_data_json[current_message_box_index].inout === "out"
                        ? ELEMENT_CLASS_NAMES[0]
                        : ELEMENT_CLASS_NAMES[1];
                currentElement.firstElementChild.classList.add(elementClassName);
            }
        }
    }

    onEndedHandle = () => {
        let player = document.getElementById("audio-text-player");
        let panel = document.getElementById("voice-text-panel");
        this.props.propSetState({
            message_box_data: [],
            message_box_index: 0,
        });
        player.currentTime = 0;
        panel.scrollTo(0,0)
        initMessageColor();
    }

    //音声プレイヤーコントロール（5秒前・5秒後）
    controlAudio = (type) => {
        let player = document.getElementById("audio-text-player");
        initMessageColor();
        if(type === "backward"){
            player.currentTime = player.currentTime - 5
        } else if (type === "forward") {
            player.currentTime = player.currentTime + 5
        }
    }

    //キーワード検索してキーワードボタン押下時、キーワードがある音声テキストに移動
    searchKeyword = (keyword) => {
        let {selected, keyword_index_arr, searching_index} = this.props.state;
        let panel = document.getElementById("voice-text-panel");
        let player = document.getElementById("audio-text-player");
        let searched_keyword_index_arr = [];
        let index_arr = [];
        let element;
        initMessageColor();
        if(!keyword_index_arr.length){
            //キーワードインデクス情報が取得されていない場合、キーワードが含まれている音声テキストを探してインデクス取得
            keyword.forEach((keyword_item)=>{
                selected.text_data_json.filter((row, index)=> {
                    if(row.transcript.toLowerCase().indexOf(keyword_item.toLowerCase()) > -1){
                        searched_keyword_index_arr.push({index:index, count:row.transcript.split(keyword_item).length-1});

                    }
                });
                searched_keyword_index_arr.sort(function(a, b){
                    return a.index - b.index
                })
            })
            searched_keyword_index_arr.forEach(row=>{
                for (let i = 0; i < row.count; i++) {
                    index_arr.push(row.index);
                }
            });
            this.props.propSetState({keyword_index_arr:index_arr});
            element = document.getElementById("message-box-" + index_arr[0]);
            //elementが存在するとキーワードが存在する音声テキストに移動
            if(element){
                let rect = element.getBoundingClientRect();
                panel.scrollTo(0, rect.bottom + panel.scrollTop -480);
                player.currentTime = selected.text_data_json[index_arr[0]].startTime;
                this.props.propSetState({
                    keyword_index_arr: index_arr,
                    searching_index: 0,
                    message_box_data: selected.text_data_json[index_arr[0]],
                    message_box_index: index_arr[0],
                    message_click_flag: true
                });
            } else {
                this.props.propSetState({
                    voice_alert_flag: true,
                    voice_alert_message: this.props.langText.Message.NoKeywordMessage
                });
            }
        } else {
            //キーワードインデクス情報が取得されている場合、キーワードが含まれている音声テキストに移動
            //最後インデクスのテキストに行ったら、最初にもどる
            let element_index = keyword_index_arr[searching_index+1] != undefined? searching_index+1: 0;
            element = document.getElementById("message-box-" + keyword_index_arr[element_index]);
            player.currentTime = selected.text_data_json[keyword_index_arr[element_index]].startTime;
            let rect = element.getBoundingClientRect();
            panel.scrollTo(0, rect.bottom + panel.scrollTop-480);
            this.props.propSetState({
                searching_index: element_index,
                message_box_data: selected.text_data_json[keyword_index_arr[element_index]],
                message_box_index: keyword_index_arr[element_index],
                message_click_flag: true
            });
        }
    }

    viewAudioControl = () =>{
        return(
            <>
            <div className = "analysis-audio-control">
                <ButtonGroup>
                    {this.props.currentPermission.playback &&
                    <>
                        <Button
                            onClick = {e=> this.controlAudio("backward")}
                        >
                            <Glyphicon glyph="backward"/>
                            {this.props.langText.Body.Before5sec}
                        </Button>
                        <Button
                            onClick = {e=> this.controlAudio("forward")}
                        >
                            {this.props.langText.Body.After5sec}
                            <Glyphicon glyph="forward"/>
                        </Button>
                    </>
                    }
                    <Button
                        onClick = {e=> this.searchKeyword(this.props.getKeywordData("keyword"))}
                        disabled = {!this.props.getKeywordData("keyword")}
                    >
                        {this.props.langText.Body.Keyword}
                        {
                        this.props.state.keywordHitCount
                        ? "(" + (this.props.state.searching_index+1)+"/"+this.props.state.keywordHitCount + ")"
                        : ""}
                    </Button>
                </ButtonGroup>
                {this.props.currentPermission.playback &&
                <audio
                    id = "audio-text-player"
                    className = "audio-player"
                    controls
                    controlsList = "nodownload"
                    onTimeUpdate={e=> this.onTimeUpdateHandle(e)}
                    onSeeked = {e=> this.onSeekChange(e)}
                    onEnded = {e => this.onEndedHandle(e)}
                    onPlay = {e=> this.props.onPlayHandle(document.getElementById('audio-text-player'))}
                >
                    <source 
                        src = {(
                            this.props.state.selected
                            && this.props.state.selected.ct60_call_histories
                            && this.props.state.selected.ct60_call_histories.voice_log_url)
                            && this.props.state.selected.ct60_call_histories.voice_log_url
                        } 
                        type="audio/ogg"
                    />
                </audio>
                }
            </div>
            </>
        )
    }

    render(){
        return(
            <>
                {this.viewAudioControl()}
            </>
        )
    }
}

/**
 * コメント表示及び登録アイテム
 * state[selected.comment_data_json]が必要
 */
export class ViewCommentElement extends AscComponent {
    //コメント編集欄が変更されるとstate更新
    editCommentData = (index, value) => {
        let comment_null_flag = !value.trim();
        this.props.state.selected.comment_data_json[index].data_edit = value;
        this.props.propSetState({comment_null_flag});
    }

    //コメント編集ボタン押下時、コメント編集欄表示かコメント更新
    onClickEditBtn = (index) => {
        let comment_edit_flag = [...this.props.state.comment_edit_flag];
        let comment_data_json = [...this.props.state.selected.comment_data_json];
        if(comment_edit_flag[index]){
            //comment_edit_flag[index]がtrueの場合、flagをfalseに変更してサーバー側に編集したコメントを送ってDB更新
            comment_edit_flag[index] = false;
            this.props.updateCommentData(index, this.props.state.selected.comment_data_json[index].data_edit);
        } else {
            //comment_edit_flag[index]がfalseの場合、編集中の他のコメントの編集flagをfalseに、
            //選択したコメントの編集flagをtrueに変更してコメントを編集できるようにする
            comment_edit_flag = comment_edit_flag.map( row => {
                row = false;
            })
            comment_edit_flag[index] = true;
            comment_data_json[index].data_edit = comment_data_json[index].data;
        }
        this.props.propSetState({comment_edit_flag, comment_data_json, comment_null_flag: false});
    }
    
    // コメント削除ボタン押す時、アラートで確認
    onClickDeleteBtn = (index) => {
        if (window.confirm(this.props.langText.Message.CommentDeleteCheck)) {
            this.props.deleteCommentData(index);
        }
    }

    //アラート閉じる
    closeAlert = () => {
        this.props.propSetState({comment_alert_flag: false});
    }

    //コメント一覧表示
    viewCommentMessage = () => {
        let returnItems = [];
        let user_check_flag = false;
        if(this.props.state.selected && this.props.state.selected.comment_data_json){
            this.props.state.selected.comment_data_json.forEach((row, index) => {
                user_check_flag = row.entry_user_email === this.props.userInfo.user_id? true: false;
                    returnItems.push(
                        <React.Fragment key = {index}>
                        <div className={"message-div"+" "+(user_check_flag? "right":"left")}>
                            <div className = {"message-box"+" "+(user_check_flag? "right":"left")}>
                                <Row>
                                    <span className="user_name">{row.entry_user_name}</span>
                                    <span className="date-time">
                                        {moment(new Date(row.created)).format("YYYY/MM/DD HH:mm:ss")}
                                    </span>
                                    {row.modified &&
                                        <span className="data-updated">
                                            {this.props.langText.Body.Updated}
                                        </span>
                                    } 
                                    <span className="analysis-btn-group" >
                                        <ButtonSpeechBubble
                                            key = "copy-btn"
                                            bsSize="xsmall"
                                            onClick={e=>this.props.copyData("comment-each",row)}
                                            className="control-button"
                                            disabled = {this.props.state.comment_edit_flag && this.props.state.comment_edit_flag[index]}
                                            DisplayIcon="copy"
                                            speechBubble={this.props.langText.Body.Copy}
                                        />
                                        {user_check_flag &&
                                            <ButtonSpeechBubble
                                                key = "edit-btn"
                                                bsSize="xsmall"
                                                onClick={e => this.onClickEditBtn(index)}
                                                className="control-button"
                                                disabled = {(this.props.state.comment_edit_flag[index] && this.props.state.comment_null_flag)}
                                                DisplayIcon="pencil"
                                                speechBubble={this.props.langText.Body.Edit}
                                            />
                                        }
                                        {user_check_flag &&
                                            <ButtonSpeechBubble
                                                key = "delete-btn"
                                                bsSize="xsmall"
                                                onClick={e => this.onClickDeleteBtn(index)}
                                                className="control-button"
                                                disabled = {(this.props.state.comment_edit_flag[index] && this.props.state.comment_null_flag)}
                                                DisplayIcon="minus"
                                                speechBubble={this.props.langText.Body.Delete}
                                            />
                                        }
                                    </span>
                                </Row>
                                {this.props.state.comment_edit_flag && this.props.state.comment_edit_flag[index]
                                ? 
                                <FormControl
                                    componentClass="textarea"
                                    className="comment-data-edit"
                                    rows="4"
                                    cols={row.data? row.data.length: 1}
                                    defaultValue={row.data}
                                    onChange={e => this.editCommentData(index, e.target.value)}
                                />
                                :<div className="comment-data">{row.data}</div>
                                }
                                
                            </div>
                        </div>
                        </React.Fragment>
                    );
                }
            );
        } else {
            returnItems.push(
                <div key="no-comment-message" className="no-comment-message">
                    {this.props.langText.Message.NoCommentMessage}
                </div>
            )
        }

        return returnItems;
    }

    //コメント入力欄・ボタン表示
    insertComment = () =>{
        return (
            <>
                <FormControl
                    componentClass="textarea"
                    rows="2"
                    key = "comment-input"
                    className = "comment-input"
                    placeholder={this.props.langText.Message.CommentInputPlaceholder}
                    onChange = {e=>this.props.propSetState({insert_comment:e.target.value})}
                    value = {this.props.state.insert_comment}
                />
                <Button
                    className="comment-insert-btn"
                    onClick={e => this.props.insertCommentData(this.props.state.insert_comment)}
                    disabled = {!this.props.state.insert_comment.trim()}
                >
                    {this.props.langText.Body.Insert}
                </Button>
            </>
        ) 
    }
    
    render(){
        return(
            <>
                {
                    //コメント入力・更新・コピーの場合アラートメッセージ表示
                    this.props.state.comment_alert_flag &&
                    <Alert bsStyle="info" className="comment-alert" onDismiss = {this.closeAlert} >
                        {this.props.state.comment_alert_message}
                    </Alert>
                }
                {this.viewCommentMessage()}
                {this.insertComment()}
            </>
        )
    }
}


const initMessageColor = () =>{
    const ELEMENT_CLASS_NAMES = ["current-message-box-right", "current-message-box-left"];
    const messages = document.getElementById("voice-text-panel");
    if (messages == null) return false;

    const children = messages.children;
    if (children == null) return false;

    for (let i = 0; i < children.length; i++) {
        if (children[i].firstElementChild) {
            children[i].firstElementChild.classList.remove(...ELEMENT_CLASS_NAMES);
        }
    }
}
