import React, {Component} from "react"
import { Panel, Radio, Col, Glyphicon } from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import Favi from "../Elements/Favi/Favi"
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css";
import moment, { relativeTimeThreshold } from 'moment';
import 'moment/locale/ja';

moment.locale('ja');

export default class CallHistoryModal extends Component {

    getCallHistoryInfoItem = () => {
        let {
            param,
            state,
            propSetState,
            langText,
            onSelectChange,
            validationHandle,
            onRadioChange,
        } = this.props;

        let search_date  = null;
        let downloadFileTypeRadio = state.downloadFileType;
        let downloadFileTypeRadioNomp3 = state.downloadFileTypeNoMp3;

        if (state.param) {
            search_date = moment(state.param.original.start_datetime).format("YYYY-MM-DD")
        }
        
        let itemArr = [];
        if (typeof state.downloadFileType !="undefined") {
            if (search_date < "2020-06-11") {
                itemArr.push(
                    <InlineForm
                        key = "file_type"
                        controlId = "file_type"
                        label = {langText.Body.Download}
                        validationState = {validationHandle("file_type")}>
                        {downloadFileTypeRadioNomp3.map((row, key) =>
                            <div key={key}>
                                <Col xs={10} className="inboundType-text">
                                <Radio
                                    name     = "download_type_radio"
                                    key      = {key}
                                    onChange = {e => onRadioChange(e, "file_type")}
                                    checked  = {row.value === state.file_type}
                                    value    = {row.value}
                                >
                                    {row.label}
                                </Radio>
                                </Col>
                            </div>
                        )}
                    </InlineForm>
                );
            } else {
                itemArr.push(
                    <InlineForm
                        key = "file_type"
                        controlId = "file_type"
                        label = {langText.Body.Download}
                        validationState = {validationHandle("file_type")}>
                        {downloadFileTypeRadio.map((row, key) =>
                            <div key={key}>
                                <Col xs={10} className="inboundType-text">
                                <Radio
                                    name     = "download_type_radio"
                                    key      = {key}
                                    onChange = {e => onRadioChange(e, "file_type")}
                                    checked  = {row.value === state.file_type}
                                    value    = {row.value}
                                >
                                    {row.label}
                                </Radio>
                                </Col>
                            </div>
                        )}
                    </InlineForm>
                );
            }
           
        }
        
        // itemArr.push(
        //     <InlineForm
        //         key = "file_type"
        //         controlId = "file_type"
        //         label = {langText.Body.Download}
        //         type = "select"
        //         options = {state.downloadFileType}
        //         onChange = {e => onSelectChange(e, "file_type")}
        //         validationState = {validationHandle("file_type")}
        //         value = {state.file_type}
        //         isClearable = {true}
        //         placeholder = {this.props.langText.SelectOption.Download_type}
        //         />
        // );
        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.DownloadFlag}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getCallHistoryInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
