import React from "react";
import AscComponent from "../../../components/AscComponent";
import CommonTable from "../Table/CommonTable";
import moment from "moment";
import { Row, Col, Button, Glyphicon, FormControl, Alert, ButtonGroup } from "react-bootstrap";
import ButtonSpeechBubble from "../../Elements/AscElements/SetButtonWithSpeechBubble";
import Chart from "react-apexcharts";
import "../../Elements/Table/CommonTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidfaIcon from "@fortawesome/free-solid-svg-icons";
import * as regularfaIcon from "@fortawesome/free-regular-svg-icons";
import SetButtonWithSpeechBubble from "../../Elements/AscElements/SetButtonWithSpeechBubble";

/**
 * 通話詳細表示
 * this.props.state.data, this.props.langText, this.props.boardWidth
 */
export class ViewCallHistoryElement extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            bookmark_flag: this.props.ct03BookmarkFlag
        }
    }

    render() {
        let columns = [
            {
                Header: this.props.langText.Body.OperatorName,
                accessor: "cm16_operators.operator_name",
                width: this.props.boardWidth.xxlarge,
                Cell: data => {
                    let returnValue = "---";
                    returnValue = data.original && data.original.cm16_operators? data.original.cm16_operators.operator_name: "---";
                    return returnValue;
                }
            },
            {
                Header: this.props.langText.Body.InOutType,
                accessor: "inout_type",
                width: this.props.boardWidth.xlarge,
            },
            {
                Header: this.props.langText.Body.ExtensionNumber,
                accessor: "extension_number",
                width: this.props.boardWidth.xlarge
            },

            {
                Header: this.props.langText.Body.ExternalNumber,
                accessor: "display_number",
                width: this.props.boardWidth.xxxlarge,
                Cell: data => {
                    let returnValue = data.value;
                    if (
                        this.props.state.data
                        && this.props.state.data.ct62_assgin_external_department_infos
                        && this.props.state.data.ct62_assgin_external_department_infos.cm61_external_numbers
                        && this.props.state.data.ct62_assgin_external_department_infos.cm61_external_numbers.memo
                    ) {
                        returnValue = data.value + "(" + this.props.state.data.ct62_assgin_external_department_infos.cm61_external_numbers.memo + ")"
                    }
                    return returnValue;
                }
            },
            {
                Header: this.props.langText.Body.DestUserNumber,
                accessor: "dest_tel_no",
                width: this.props.boardWidth.xxxlarge,
            },
            {
                Header: this.props.langText.Body.StartTime,
                accessor: "start_datetime",
                Cell: data => {
                    return data.value ? this.getMomentTime({ date: data.value, format: "YYYY/MM/DD HH:mm:ss" }) : "";
                }
            }
        ];

        let bookmarkPermission = null; 
        if (this.props.userInfo && this.props.userInfo.permission_json_data) {
            bookmarkPermission = this.props.userInfo.permission_json_data.find(
                permission => permission.controller_id === "Bookmark"
            );
        }
        if (bookmarkPermission && bookmarkPermission.read) {
            columns.push({
                Header: this.props.langText.Body.Bookmark,
                accessor: "ct03_user_bookmarks",
                Cell: data => {
                    let flag = this.state.bookmark_flag;
                    let bookmark_id = null;
                    
                    return(
                        <SetButtonWithSpeechBubble
                            key={"bookmark-btn" + data.index}
                            bsSize="xsmall"
                            onClick={async () => {
                                try {
                                    this.setState({ bookmark_btn_flag: true });
                                    let path = flag ? "Common/deleteBookmark" : "Common/insertBookmark";
                                    let body = flag ? {
                                        id: this.state && this.state.bookmark_id
                                            ? this.state.bookmark_id
                                            : flag
                                    } : {
                                        ct60_id: this.props.state.data.id
                                    };
                                    let result = await this.ascAxios("post", path, body);
                                    if (result.data.id) bookmark_id = result.data.id;  
    
                                    this.toast(
                                        flag
                                        ? this.props.langText.Message.BookmarkDelete
                                        : this.props.langText.Message.BookmarkRegister
                                    );
    
                                    this.setState({ bookmark_flag: !flag, bookmark_id: bookmark_id });
                                } catch (err) {
                                    console.error(err);
                                    this.showErrorObjectMesssage(err);
                                } finally {
                                    this.setState({ bookmark_btn_flag: false });
                                }
                            }}
                            className="control-button"
                            disabled={this.state && this.state.bookmark_btn_flag ? this.state.bookmark_btn_flag : false}
                            DisplayFontAwesomeIcon={ flag ? solidfaIcon.faBookmark : regularfaIcon.faBookmark }
                            iconSize="1x"
                            speechBubble={ flag ? this.props.langText.Body.Bookmarked : this.props.langText.Body.BookmarkRegister}
                        />
                    );
                }
            });
        }
        
        let data = [this.props.state.data];

        return (
            <CommonTable
                className="call-history-analysis-table"
                label={this.props.langText.Body.CallHistoryName}
                showPagination={false}
                columns={columns}
                data={data}
                showPaginationBottom={false}
                sortable={false}
                pageSize={1}
                loadingText={this.props.langText.Table.LoadingText}
                noDataText={this.props.langText.Table.NoDataText}
                rowsText={this.props.langText.Table.RowsText}
            />
        );
    }
}

/**
 * 音声再生表示
 */
export class ViewPlayVoiceElement extends AscComponent {
    render() {
        return (
            <div id="play-voice-chart">
                <Chart
                    options={this.props.state.play_voice_options}
                    series={this.props.state.play_voice_series}
                    type="area"
                    height={300}
                />
            </div>
        );
    }
}


export class ViewAudioControlElement extends AscComponent {
    constructor(props) {
        super(props);
        const {
            selected
        } = this.props.state;

        if (selected && selected.text_data_json && selected.text_data_json.length > 0) {
            const lastIndex = selected.text_data_json.length - 1;
            this.props.propSetState({
                lastMessageEndTime: selected.text_data_json[lastIndex].endTime
            })
        }
    }
    //音声プレイヤーの再生バー変更時、表示中のテキスト変更等操作
    onSeekChange = (e) => {
        let {
            selected,
            message_click_flag
        } = this.props.state;
        let player = document.getElementById("audio-text-player");
        let panel = document.getElementById("voice-text-panel");
        let element, element2;
        this.props.propSetState({ message_click_flag: false });
        if (!message_click_flag) {
            //音声テキストメッセージフラグがfalseだったら、音声プレイヤーの現在再生時間に表示音声テキストメッセージを合わせる
            for (let i = 0; i < selected.text_data_json.length; i++) {
                let currentTime = Math.round(player.currentTime);
                let startTime = Math.round(selected.text_data_json[i].startTime);
                let endTime = selected.text_data_json[i + 1] ? Math.round(selected.text_data_json[i + 1].startTime) : Math.round(selected.text_data_json[i].endTime);
                if (currentTime >= startTime && currentTime <= endTime) {
                    element = document.getElementById("message-box-" + i);
                    element2 = document.getElementById("message-box-" + (i + 1));
                    let rect = element.getBoundingClientRect();
                    let rect2 = element2 ? element2.getBoundingClientRect() : element.getBoundingClientRect();
                    panel.scrollTo(0, (rect.bottom + rect2.bottom) / 2 + panel.scrollTop - 440);
                    this.props.propSetState({
                        message_box_data: selected.text_data_json[i],
                        message_box_index: i,
                    });
                    break;
                }
            }
        }
    }

    //時間アップデート時、表示中のテキスト操作
    onTimeUpdateHandle = (e) => {
        let player = document.getElementById("audio-text-player");
        let {
            message_box_data,
            message_box_index,
            selected,
            lastMessageEndTime
        } = this.props.state;
        const ELEMENT_CLASS_NAMES = ["current-message-box-right", "current-message-box-left"];
        if (
            message_box_data === undefined ||
            message_box_data.length === 0 ||
            Math.floor(player.currentTime) === 0
        ) {
            //message_box_dataが無い場合、最初の再生情報を入れる
            this.props.propSetState({
                message_box_data: selected.text_data_json[0],
                message_box_index: 0,
                message_click_flag: false
            });

            const currentElement = document.getElementById("message-box-0");
            if (currentElement) {
                const elementClassName =
                    selected.text_data_json[0].inout === "out"
                        ? ELEMENT_CLASS_NAMES[0]
                        : ELEMENT_CLASS_NAMES[1];
                currentElement.firstElementChild.classList.add(elementClassName);
            }
        } else if (
            message_box_data
            && (message_box_data.startTime || message_box_data.startTime === 0)
            && (Math.floor(player.currentTime) >= Math.ceil(message_box_data.endTime))
        ) {
            //音声テキストデータ有り・startTime有り・音声テキストデータと音声プレイヤーの現在時間一致して、
            //自動スクロールフラグがtrueだったらパンネルスクロールさせる
            if (this.props.state.auto_scroll_flag) {
                document.getElementById("voice-text-panel").scrollBy(0, 85.5);
            }

            this.props.propSetState({
                message_box_data: selected.text_data_json[message_box_index + 1],
                message_box_index: message_box_index + 1,
                message_click_flag: false
            });

            const current_message_box_index = message_box_index + 1;
            if (Math.floor(player.currentTime) > lastMessageEndTime) return false;

            const prevElementIndex = current_message_box_index > 0 ? current_message_box_index - 1 : 0
            const prevElement = document.getElementById("message-box-" + prevElementIndex);
            if (prevElement)
                prevElement.firstElementChild.classList.remove(...ELEMENT_CLASS_NAMES);

            const currentElement = document.getElementById("message-box-" + current_message_box_index);
            if (currentElement) {
                const elementClassName =
                    selected.text_data_json[current_message_box_index].inout === "out"
                        ? ELEMENT_CLASS_NAMES[0]
                        : ELEMENT_CLASS_NAMES[1];
                currentElement.firstElementChild.classList.add(elementClassName);
            }
        }
    }

    onEndedHandle = () => {
        let player = document.getElementById("audio-text-player");
        let panel = document.getElementById("voice-text-panel");
        this.props.propSetState({
            message_box_data: [],
            message_box_index: 0,
        });
        player.currentTime = 0;
        panel.scrollTo(0, 0)
        initMessageColor();
    }

    //音声プレイヤーコントロール（5秒前・5秒後）
    controlAudio = (type) => {
        let player = document.getElementById("audio-text-player");
        initMessageColor();
        if (type === "backward") {
            player.currentTime = player.currentTime - 5
        } else if (type === "forward") {
            player.currentTime = player.currentTime + 5
        }
    }

    viewAudioControl = () => {
        return (
            <>
                <Row className="history-analysis-audio-control">
                    <Col xs={2} md={2}>
                        <ButtonGroup className="history-analysis-btn-grp">
                            {this.props.currentPermission.playback &&
                                <>
                                    <Button
                                        className="history-analysis-btn"
                                        onClick={e => this.controlAudio("backward")}
                                    >
                                        <Glyphicon glyph="backward" />
                                        {this.props.langText.Body.Before5sec}
                                    </Button>
                                    <Button
                                        className="history-analysis-btn"
                                        onClick={e => this.controlAudio("forward")}
                                    >
                                        {this.props.langText.Body.After5sec}
                                        <Glyphicon glyph="forward" />
                                    </Button>
                                </>
                            }
                        </ButtonGroup>
                    </Col>
                    <Col xs={10} md={10}>
                        {this.props.currentPermission.playback &&
                            <audio
                                id="audio-text-player"
                                className="audio-player"
                                controls
                                controlsList="nodownload"
                                onTimeUpdate={e => this.onTimeUpdateHandle(e)}
                                onSeeked={e => this.onSeekChange(e)}
                                onEnded={e => this.onEndedHandle(e)}
                                onPlay={e => this.props.onPlayHandle(document.getElementById('audio-text-player'))}
                            >
                                <source
                                    src={(
                                        this.props.state.selected
                                        && this.props.state.selected.ct60_call_histories
                                        && this.props.state.selected.ct60_call_histories.voice_log_url)
                                        && this.props.state.selected.ct60_call_histories.voice_log_url
                                    }
                                    type="audio/ogg"
                                />
                            </audio>
                        }
                    </Col>
                </Row>
            </>
        )
    }

    render() {
        return (
            <>
                {this.viewAudioControl()}
            </>
        )
    }
}


/**
 * スコア表示
 */
export class ViewScoreElement extends AscComponent {
    render() {
        return (
            <div id="score-chart">
                <Chart
                    options={this.props.state.score_options}
                    series={this.props.state.score_series}
                    type="radialBar"
                    height={300}
                />
            </div>
        );
    }
}

/**
 * Talk:Listen比率
 */
export class ViewTalkListenElement extends AscComponent {
    render() {
        return (
            <div id="talk-listen-chart">
                <Chart
                    options={this.props.state.talk_listen_options}
                    series={this.props.state.talk_listen_series}
                    type="pie"
                    height={240}
                />
            </div>
        );
    }
}

/**
 * その他表示
 */

export class ViewOthersElement extends AscComponent {
    render() {
        return (
            <table className="others-table">
                <tbody>
                    <tr>
                        <th>{this.props.langText.Body.SilenceCount}</th>
                        <td>{this.props.state.ct86_data.silence_count}{this.props.langText.Body.TimeCount}</td>
                    </tr>
                    <tr>
                        <th>{this.props.langText.Body.TalkConflictCount}</th>
                        <td>{this.props.state.ct86_data.talk_conflict_count}{this.props.langText.Body.TimeCount}</td>
                    </tr>
                    <tr>
                        <th>{this.props.langText.Body.RallyingCount}</th>
                        <td>{this.props.state.ct86_data.rallying_count}{this.props.langText.Body.TimeCount}</td>
                    </tr>
                    <tr>
                        <th>{this.props.langText.Body.FillerCount}</th>
                        <td>{this.props.state.ct86_data.filler_count !== null? this.props.state.ct86_data.filler_count+this.props.langText.Body.TimeCount: this.props.langText.Body.LoadingData}</td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

/**
 * 基本周波数
 */
export class ViewFrequencyElement extends AscComponent {
    render() {
        return (
            <div id="fundamental-frequency-chart">
                <Chart
                    options={this.props.state.fundamental_frequency_options}
                    series={this.props.state.fundamental_frequency_series}
                    type="bar"
                    height={200}
                />
            </div>
        );
    }
}

/**
 * 抑揚の強弱
 */
export class ViewAccentElement extends AscComponent {
    render() {
        return (
            <div id="accent-chart">
                <Chart
                    options={this.props.state.accent_options}
                    series={this.props.state.accent_series}
                    type="bar"
                    height={200}
                />
            </div>
        );
    }
}

/**
 * 話速
 */
export class ViewTalkSpeedElement extends AscComponent {
    render() {
        let isNull = (this.props.state.talk_speed_series[0].data).filter(value => value === null);
        if(isNull.length) {
            return(
                <div className="speed-loading">
                    {this.props.langText.Body.LoadingData}
                </div>
            )
        } else {
            return (
                <div id="speaking-rate-chart">
                    <Chart
                        options={this.props.state.talk_speed_options}
                        series={this.props.state.talk_speed_series}
                        type="bar"
                        height={200}
                    />
                </div>
            );
        }
        
    }
}

/**
 * 音声テキスト表示
 */
export class ViewVoiceTextElement extends AscComponent {
    //音声テキスト編集欄が変更されるとstate更新
    editVoiceData = (index, value) => {
        let voice_null_flag = !value.trim();
        let text_data_json = [...this.props.state.selected.text_data_json];
        text_data_json[index].transcript_edit = value;
        this.props.propSetState({ text_data_json, voice_null_flag });
    }

    //音声テキスト編集ボタン押下時、音声テキスト編集欄表示か音声テキスト更新
    onClickEditBtn = (index) => {
        let voice_edit_flag = [...this.props.state.voice_edit_flag];
        let text_data_json = [...this.props.state.selected.text_data_json];
        if (voice_edit_flag[index]) {
            //voice_edit_flag[index]がtrueの場合、flagをfalseに変更してサーバー側に編集した音声テキストを送ってDB更新
            voice_edit_flag[index] = false;
            this.props.updateVoiceData(index, this.props.state.selected.text_data_json[index].transcript_edit);
        } else {
            //voice_edit_flag[index]がfalseの場合、編集中の他の音声テキストの編集flagをfalseに、
            //選択した音声テキストの編集flagをtrueに変更して音声テキストを編集できるようにする
            voice_edit_flag = voice_edit_flag.map(row => {
                row = false;
            })
            voice_edit_flag[index] = true;
            text_data_json[index].transcript_edit = text_data_json[index].transcript;
        }
        this.props.propSetState({ voice_edit_flag, text_data_json, voice_null_flag: false });
    }

    //アラート閉じる
    closeAlert = () => {
        this.props.propSetState({ voice_alert_flag: false });
    }

    //音声テキスト一覧表示
    viewVoiceMessage = () => {
        let returnItems = [];
        let user_check_flag = false;
        if (this.props.state.selected && this.props.state.selected.text_data_json.length) {
            this.props.state.selected.text_data_json.forEach((row, index) => {
                user_check_flag = row.inout === "out" ? true : false;
                returnItems.push(
                    <React.Fragment key={index}>
                        <div id={"message-box-" + index} className={"message-div" + " " + (user_check_flag ? "right" : "left")}>
                            <div className={"history-analysis message-box left" + " " + (user_check_flag ? "right" : "left")}>
                                <Row>
                                    <span className={"voice-time" + " " + (user_check_flag ? "right" : "left")} onClick={e => this.messageClickHandle(index)}>
                                        {this.secToTime(row.startTime || 0)}
                                    </span>
                                    {row.modified &&
                                        <span className="data-updated">
                                            {this.props.langText.Body.Updated}
                                        </span>
                                    }
                                    <span className="analysis-btn-group" >
                                        <ButtonSpeechBubble
                                            key="copy-btn"
                                            bsSize="xsmall"
                                            onClick={e => this.props.copyData("voice-each", row)}
                                            className="control-button"
                                            disabled={this.props.state.voice_edit_flag && this.props.state.voice_edit_flag[index]}
                                            DisplayIcon="copy"
                                            speechBubble={this.props.langText.Body.Copy}
                                        />
                                        {this.props.currentPermission.edit &&
                                            <ButtonSpeechBubble
                                                key="edit-btn"
                                                bsSize="xsmall"
                                                onClick={e => this.onClickEditBtn(index)}
                                                className="control-button"
                                                disabled={this.props.state.voice_edit_flag[index] && this.props.state.voice_null_flag}
                                                DisplayIcon="pencil"
                                                speechBubble={this.props.langText.Body.Edit}
                                            />
                                        }
                                    </span>
                                </Row>
                                {this.props.state.voice_edit_flag && this.props.state.voice_edit_flag[index]
                                    ?
                                    //編集ボタン押下時、音声テキスト編集欄表示・編集可能
                                    <FormControl
                                        componentClass="textarea"
                                        className="voice-data-edit"
                                        rows="2"
                                        cols={row.transcript ? row.transcript.length : 1}
                                        defaultValue={row.transcript}
                                        onChange={e => this.editVoiceData(index, e.target.value)}
                                    />
                                    :
                                    // 検索したキーワードはハイライトされて表示
                                    <div className="voice-data" onClick={e => this.messageClickHandle(index)}>
                                        {row.transcript}
                                    </div>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                );
                //最後にマージン用div入れる
                if (index === this.props.state.selected.text_data_json.length - 1) {
                    returnItems.push(
                        <div className="voice-last-index" key="last-index">
                            &nbsp;
                        </div>
                    )
                }
            });
        } else {
            //音声テキストが存在しない場合、メッセージ表示
            returnItems.push(
                <div key="no-voice-message" className="no-voice-message">
                    {this.props.langText.Message.NoVoiceMessage}
                </div>
            )
        }
        return returnItems;
    }

    //音声テキストボクスクリックしたら、音声テキストの時点から音声再生
    messageClickHandle = (index) => {
        let player = document.getElementById("audio-text-player");
        let {
            selected
        } = this.props.state;
        this.props.propSetState({
            message_box_data: selected.text_data_json[index],
            message_box_index: index,
            message_click_flag: true
        });

        player.currentTime = selected.text_data_json[index].startTime;
        initMessageColor();
    }


    render() {
        return (
            <div id="voice-text-panel" className={"history-analysis-panel voice-text-panel " + (this.props.state.auto_scroll_flag && "pannel-hidden")}>
                {
                    //ボイステキスト入力・更新・コピーの場合アラートメッセージ表示
                    this.props.state.voice_alert_flag &&
                    <Alert bsStyle="info" className="voice-alert" onDismiss={this.closeAlert} >
                        {this.props.state.voice_alert_message}
                    </Alert>
                }
                {this.viewVoiceMessage()}
            </div>
        )
    }
}

/**
 * コメント表示及び登録アイテム
 * state[selected.comment_data_json]が必要
 */
export class ViewCommentElement extends AscComponent {
    //コメント編集欄が変更されるとstate更新
    editCommentData = (index, value) => {
        let comment_null_flag = !value.trim();
        this.props.state.selected.comment_data_json[index].data_edit = value;
        this.props.propSetState({ comment_null_flag });
    }

    //コメント編集ボタン押下時、コメント編集欄表示かコメント更新
    onClickEditBtn = (index) => {
        let comment_edit_flag = [...this.props.state.comment_edit_flag];
        let comment_data_json = [...this.props.state.selected.comment_data_json];
        if (comment_edit_flag[index]) {
            //comment_edit_flag[index]がtrueの場合、flagをfalseに変更してサーバー側に編集したコメントを送ってDB更新
            comment_edit_flag[index] = false;
            this.props.updateCommentData(index, this.props.state.selected.comment_data_json[index].data_edit);
        } else {
            //comment_edit_flag[index]がfalseの場合、編集中の他のコメントの編集flagをfalseに、
            //選択したコメントの編集flagをtrueに変更してコメントを編集できるようにする
            comment_edit_flag = comment_edit_flag.map(row => {
                row = false;
            })
            comment_edit_flag[index] = true;
            comment_data_json[index].data_edit = comment_data_json[index].data;
        }
        this.props.propSetState({ comment_edit_flag, comment_data_json, comment_null_flag: false });
    }

    // コメント削除ボタン押す時、アラートで確認
    onClickDeleteBtn = (index) => {
        if (window.confirm(this.props.langText.Message.CommentDeleteCheck)) {
            this.props.deleteCommentData(index);
        }
    }

    //アラート閉じる
    closeAlert = () => {
        this.props.propSetState({ comment_alert_flag: false });
    }

    //コメント一覧表示
    viewCommentMessage = () => {
        let returnItems = [];
        let user_check_flag = false;
        if (this.props.state.selected && this.props.state.selected.comment_data_json) {
            this.props.state.selected.comment_data_json.forEach((row, index) => {
                user_check_flag = row.entry_user_email === this.props.userInfo.user_id ? true : false;
                returnItems.push(
                    <React.Fragment key={index}>
                        <div className={"message-div" + " " + (user_check_flag ? "right" : "left")}>
                            <div className={"message-box" + " " + (user_check_flag ? "right" : "left")}>
                                <Row>
                                    <span className="user_name">{row.entry_user_name}</span>
                                    <span className="date-time">
                                        {moment(new Date(row.created)).format("YYYY/MM/DD HH:mm:ss")}
                                    </span>
                                    {row.modified &&
                                        <span className="data-updated">
                                            {this.props.langText.Body.Updated}
                                        </span>
                                    }
                                    <span className="analysis-btn-group" >
                                        <ButtonSpeechBubble
                                            key="copy-btn"
                                            bsSize="xsmall"
                                            onClick={e => this.props.copyData("comment-each", row)}
                                            className="control-button"
                                            disabled={this.props.state.comment_edit_flag && this.props.state.comment_edit_flag[index]}
                                            DisplayIcon="copy"
                                            speechBubble={this.props.langText.Body.Copy}
                                        />
                                        {user_check_flag &&
                                            <ButtonSpeechBubble
                                                key="edit-btn"
                                                bsSize="xsmall"
                                                onClick={e => this.onClickEditBtn(index)}
                                                className="control-button"
                                                disabled={(this.props.state.comment_edit_flag[index] && this.props.state.comment_null_flag)}
                                                DisplayIcon="pencil"
                                                speechBubble={this.props.langText.Body.Edit}
                                            />
                                        }
                                        {user_check_flag &&
                                            <ButtonSpeechBubble
                                                key="delete-btn"
                                                bsSize="xsmall"
                                                onClick={e => this.onClickDeleteBtn(index)}
                                                className="control-button"
                                                disabled={(this.props.state.comment_edit_flag[index] && this.props.state.comment_null_flag)}
                                                DisplayIcon="minus"
                                                speechBubble={this.props.langText.Body.Delete}
                                            />
                                        }
                                    </span>
                                </Row>
                                {this.props.state.comment_edit_flag && this.props.state.comment_edit_flag[index]
                                    ?
                                    <FormControl
                                        componentClass="textarea"
                                        className="comment-data-edit"
                                        rows="4"
                                        cols={row.data ? row.data.length : 1}
                                        defaultValue={row.data}
                                        onChange={e => this.editCommentData(index, e.target.value)}
                                    />
                                    : <div className="comment-data">{row.data}</div>
                                }

                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            );
        } else {
            returnItems.push(
                <div key="no-comment-message" className="no-comment-message">
                    {this.props.langText.Message.NoCommentMessage}
                </div>
            )
        }

        return returnItems;
    }

    //コメント入力欄・ボタン表示
    insertComment = () => {
        return (
            <>
                <FormControl
                    componentClass="textarea"
                    rows="2"
                    key="comment-input"
                    className="comment-input"
                    placeholder={this.props.langText.Message.CommentInputPlaceholder}
                    onChange={e => this.props.propSetState({ insert_comment: e.target.value })}
                    value={this.props.state.insert_comment}
                />
                <Button
                    className="comment-insert-btn"
                    onClick={e => this.props.insertCommentData(this.props.state.insert_comment)}
                    disabled={!this.props.state.insert_comment.trim()}
                >
                    {this.props.langText.Body.Insert}
                </Button>
            </>
        )
    }

    render() {
        return (
            <>
                {
                    //コメント入力・更新・コピーの場合アラートメッセージ表示
                    this.props.state.comment_alert_flag &&
                    <Alert bsStyle="info" className="comment-alert" onDismiss={this.closeAlert} >
                        {this.props.state.comment_alert_message}
                    </Alert>
                }
                {this.viewCommentMessage()}
                {this.insertComment()}
            </>
        )
    }
}


const initMessageColor = () => {
    const ELEMENT_CLASS_NAMES = ["current-message-box-right", "current-message-box-left"];
    const messages = document.getElementById("voice-text-panel");
    if (messages == null) return false;

    const children = messages.children;
    if (children == null) return false;

    for (let i = 0; i < children.length; i++) {
        if (children[i].firstElementChild) {
            children[i].firstElementChild.classList.remove(...ELEMENT_CLASS_NAMES);
        }
    }
}
